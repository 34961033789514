import axios                    from 'axios';
import { createStore }          from 'vuex';
import { db,firebase, storage } from '../firebaseConfig'

export default createStore({
    state : {
        user                : null,
        usuario             : {},
        usuarios            : [],
        usuarioProyecto     : null,
        userData            : null,
        loadedEnd           : false,
        apps                : [],
        paginas             : [],
        paginaIdx           : 0,
        proyectos           : [],
        proyecto            : {},
        instrumento         : {},
        captura             : {},
        seleccion           : null,
        /** temporales */
        pacientes           : [],
        paciente            : {},
        pacienteIdx         : -1,
        existePaciente      : true,
        consultas           : [],
        captures            : {},
        askAddPaciente      : false,
        menu                : null,
        expediente          : null,
        instrumentosHeader  : [],
        georeferencias      : [],
        speach              : null,
        referencias         : {},
        semaforo            : false,
        // datasets            : [],
        datasets            : {},
        datasetKey          : null,
    },
    getters : {
        /** */
        getUsuarios:state               => state.usuarios,
        getApps:state                   => state.apps,
        getExpediente:state             => state.expediente,
        getUser:state                   => state.user,
        getUsuario:state                => state.usuario,
        getMenu:state                   => state.menu,
        getConsultas:state              => state.consultas,
        getPreclinica:state             => state.consultas,
        // getPreclinica:state             => state.consultas.filter( f => f.cita_ && !('preclinica_' in f.cita_)),
        getClinica:state                => state.consultas.filter( f => f.consulta && ('preclinica' in f.consulta)),
        getLaboratorioOrders:state      => state.consultas.filter( f => f.consulta && ('lab' in f.consulta)),
        existPaciente:state             => state.existePaciente,
        getPacientes:state              => state.pacientes,
        getPaciente:state               => state.paciente,
        getPacienteIdx:state            => state.pacienteIdx>-1?state.consultas[state.pacienteIdx]:{},
        getAskAddPaciente:state         => state.askAddPaciente,
        /** genericas */
        getSeleccion:state              => state.seleccion,
        getPagina:(state)               => state.paginas[state.paginaIdx],
        hasPrev:(state)                 => state.paginaIdx > 0,
        hasNext:(state)                 => state.paginaIdx < (state.paginas.length -1),
        getProyectos:(state)            => state.proyectos,
        getProyectosTree:state          => state.proyectos.map( m => Object.assign({}, {data : m, children : m.instrumentos.map( mi => Object.assign({}, {data : mi})) }) ),
        getProyecto:(state)             => state.proyecto,
        getInstrumento:(state)          => state.instrumento,
        getCaptura:(state)              => state.captura,
        getCaptures:(state)             => state.captures,
        getConsulta:(state) 	        => state.consulta,
        getUsuarioProyecto:(state)      => state.usuario.opciones[state.usuarioProyecto],
        getInstrumentosHeader:(state)   => state.instrumentosHeader,
        getGeoreferencias:(state)       => state.georeferencias,
        getSpeach:(state)               => state.speach,
        getReferencias:(state)          => state.referencias,
        getSemaforo:(state)             => state.semaforo,
        getDataset:(state)              => state.datasets,
        getDatasetByKey:(state)         => (state.datasetKey)?state.datasets[state.datasetKey]:{},
        getDatasetKey:(state)           => state.datasetKey,
    },
    mutations : {
        addApp:(state,app)                  => state.apps.push(app),
        setUser:(state,user)                => state.user = user,
        addUsuario:(state,user)             => state.usuarios.push(user),
        setUsuario:(state,usuario)          => state.usuario = usuario,
        setUserData:(state,user)            => state.userData = user,
        addDependencia:(state,data)         => {
            state.instrumento.hijos = state.instrumento.hijos || [] ;
            state.instrumento.hijos.push(data)
        },
        setMenu:(state,menu)                => state.menu = menu,
        addConsulta:(state,data)            => state.consultas.push(data),
        changeConsulta:(state,data)         => state.consultas.splice(data.index, 1, data.data),
        removeConsulta:(state,index)        => state.consultas.splice(index, 1),
        setConsulta:(state,id)              => state.consulta = state.consultas.filter( f => f._id === id)[0],
        setConsultas:(state,data)           => state.consultas = data.map( m => m),
        searchPacienteById:(state,_id)      => {
            const index = state.consultas.findIndex( f => f._id === _id)
            state.pacienteIdx = index
        },
        resetPacienteIdx:state              => state.pacienteIdx = -1,
        setAskAddPaciente:(state,ask)       => state.askAddPaciente = ask,
        setExistePaciente:(state,existe)    => state.existePaciente = existe,
        cargaNuevo:(state, nuevo)           => state.creaNuevo = nuevo,
        addPacienteCita:(state, info)       => {
            if ('cita_' in state.paciente){
                state.paciente.cita_[info.campo] = info.data
            }else{
                state.paciente.cita_ = { [info.campo] : info.data }
            }
        },
        addPaciente:(state, data)           => state.pacientes.push(data),
        setPaciente:(state,paciente)        => {
            if (state.paciente && (paciente !== state.paciente))
                delete state.paciente.seleccionado
            state.paciente = paciente
        },
        setProyectoById:(state,id)          => state.proyecto = Object.assign({},state.proyectos.filter(f => f._id === id )[0]),
        setInstrumentoById:(state,id)       => {
            // const inst = state.proyecto.instrumentos.filter(f => f._id === id )
            const inst = state.proyecto.contenido.filter(f => f._id === id )
            console.log('setInstrumentoById',inst)
            if (inst.length === 1){
                inst[0].contenido.sort((a,b) => ((a.orden>b.orden)?1:((a.orden<b.orden)?-1: 0)))
                state.instrumento = inst[0]
            }
        },
        updateInstrumento:(state, instrumento) => {
            const idx = state.proyectos.findIndex(f => f._id === instrumento._id) ;
            if (idx > -1 )
                state.proyectos.splice(idx,1,instrumento)
        },
        setSeleccion:(state, seleccion)     => state.seleccion = seleccion,
        setCaptura:(state,captura)          => state.captura = Object.assign({},captura),
        // setInstrumento:(state,index)        => state.instrumento = Object.assign({},state.proyecto.instrumentos[index]),
        // setInstrumento:(state,index)        => state.instrumento = state.proyecto.contenido[index],
        setInstrumento:(state,registro)        => {
            const index = state.proyecto.contenido.findIndex( i => i._id === registro._id ) ;
            state.instrumento = state.proyecto.contenido[index]
        },
        setProyecto:(state,index)           => state.proyecto = Object.assign({},state.proyectos[index]),
        
        setPaginas:(state,paginas)          => state.paginas = [...paginas],
        prevPagina:(state)                  => { if (state.paginaIdx > 0) state.paginaIdx-- },
        nextPagina:(state)                  => { if (state.paginaIdx < state.paginas.length) state.paginaIdx++ },
        addProyectos:(state,data)           => state.proyectos.push.apply(state.proyectos, data),
        /**
         * se cambio la funcion addProyecto, 
         * para poder agregar un proyecto en especifico y verificar si el mismo ya esta cargado, 
         * si el mismo esta en memoria es porq se hizo un cargado inicial que permite una lectura rapida 
         * sin carga
         * @param {*} state 
         * @param {*} data 
         */
        addProyecto:(state,data)            => {state.proyectos.push(data)},
        updateProyecto:(state,data)         => {
            const r = state.proyectos.findIndex( f => f._id === data._id ) ;
            state.proyectos.splice(r,1,data) ;
            state.proyecto = Object.assign({}, state.proyectos[r]) ;
        },
        setCapture:(state,data)             => state.captures = Object.assign(Object.create({}),state.captures, { [data.capture] : data.data }),
        resetCapture:(state,name)           => delete state.captures[name],
        appendExpediente:(state,part)        => {
            state.expediente = state.existePaciente || {}
            state.expediente[part.name] = part.data
        },
        // proceso de busqueda
        setPacientes:(state, pacientes)     => state.pacientes = pacientes,
        setUsuarioProyecto:(state,proyecto) => state.usuarioProyecto = state.usuario.opciones.findIndex( f => f.path.split('/')[f.path.split('/').length -1] === proyecto),
        setUsuarioActivity:(state,activity) => state.usuario.opciones[state.usuarioProyecto].activity = [...activity],
        addUsuarioActivity:(state,activity) => state.usuario.opciones[state.usuarioProyecto].activity.push(activity),
        // instrumentos en el proyecto actual
        addInstrumento:(state, instrumento) => {
            if (!('contenido' in state.proyecto)){
                state.proyecto.contenido = []
            }
            state.proyecto.contenido.push(instrumento)
        },
        // agrega una pregunta al intrumento referenciado
        addPregunta:(state,pregunta)        => {
            if (!('contenido' in state.instrumento ))
                state.instrumento.contenido = [];
                
            state.instrumento.contenido.push(pregunta) ;
        },
        setPregunta:(state,pregunta)        => {
            var p = state.instrumento.contenido.findIndex( f => f._id === pregunta._id ) ;
            state.instrumento.contenido.splice(p,1, pregunta) ;
            p = state.proyecto.instrumentos.findIndex( f => f._id === state.instrumento._id ) ;
            state.proyecto.instrumentos.splice(p,1 ,state.instrumento );
            p = state.proyectos.findIndex( f => f._id === state.proyecto._id ) ;
            state.proyectos.splice(p,1,state.proyecto) ;
        },
        setInstrumentosHeader:(state,header)=> {
            state.instrumentosHeader = header ;
        },
        addRespuestas:(state, respuestas)    => {
            const pregunta  = state.instrumento.contenido.findIndex( f => f._id === respuestas.pregunta ) ;
            console.log('respuestas',respuestas );
            console.log('respuestas',pregunta );
            
            if (!('contenido' in state.instrumento.contenido[pregunta]))
                state.instrumento.contenido[pregunta].contenido = []
            
            console.log('contenido', state.instrumento.contenido[pregunta].contenido);

            state.instrumento.contenido[pregunta].contenido.push.apply(respuestas.data.data) ;
        },

        addGeoreferencias:(state, datos)    => state.georeferencias.push(...datos) ,
        addGeoreferencia:(state, datos)     => state.georeferencias.push(datos) ,
        setGeoreferencias:(state)           => state.georeferencias.splice(0,state.georeferencias.length ),
        toSpeech:(state, spaech)            => state.speach = spaech ,
        salvarInformacion:(state,referencia)=> state.referencias[referencia.referencia] = referencia,
        deleteInformacion:(state,referencia)=> delete state.referencias[referencia.referencia],
        
        //datasets de informacion georeferenciada
        setSemaforo:(state, semaforo)       => state.semaforo = semaforo,
        // addColor2Map:(state)                => state.
        // addDatasets:(state,dataset)         => state.datasets.push(dataset),
        addDatasets:(state,dataset)         => state.datasets[dataset._id] = dataset,
        setDatasetKey:(state,key)           => {
            if (state.datasetKey){
                state.datasets[key] = state.datasets[state.datasetKey]
                delete state.datasets[state.datasetKey]
            }
            state.datasetKey = key
        },
        setDatasetHeaders:(state,headers)   => {
            if (!('meta' in state.datasets[dataset._id]))
                state.datasets[dataset._id].meta = []

            state.datasets[dataset._id].meta.headers = headers
        },
        setDatasetCampos:(state,campos)   => {
            if (!('meta' in state.datasets[dataset._id]))
                state.datasets[dataset._id].meta = []

            state.datasets[dataset._id].meta.campos = campos
        },
        // setDatasetKey:(state,key)           => state.datasetKey = key,
    },

    
    actions : {
        resetAll({commit}){
            commit('setCapture',{});
            commit('setPaciente',{});
            commit('setPacientes',{}) ;
        },
        /**
         Use de MongoDB
         */
        send2Server({},informacion){
            const r = `${process.env.VUE_APP_API_ENDPOINT}sequia` ;
            return new Promise((resolve,reject) => {
                axios.request({
                    url     : r,
                    method  : 'POST',
                    headers : {
                        'Content-Type' : 'application/json'
                    },
                    data    : JSON.stringify({ info : informacion })
                }).then(
                    (res) => {
                        if (res.status === 200){
                            console.log('informacion almacenada',res.data)
                            resolve()
                        }
                    },
                    (error) => {
                        console.log('error', error);
                        reject()
                    }
                )
            })
        },
        /**
         * 
         * @param {*} context : enlaces a todos los contenidos de las operaciones del store
         * @param {*} info : informacion a almacenar
         * @returns promesa con exito o fracaso de la accion
         */ 
        savePacienteMG(context,info){
            const r = `${process.env.VUE_APP_API_ENDPOINT}expedientes` ;
            return new Promise((resolve, reject) => {
                axios.request({
                    url         : r,
                    method      : 'POST',
                    headers     : {
                        'content-type' : 'application/json'
                    },
                    data        : JSON.stringify(info)
                }).then(
                    res => {
                        if (res.code !== 200){
                            reject()
                        }else{
                            context.commit('addPaciente',info) ;
                            resolve() ;
                        }
                    }
                )
            })
        },

        buscarMG({commit}, param){
            commit('setExistePaciente',true)
            const r = `${process.env.VUE_APP_API_ENDPOINT}expedientes/${param}` ;
            console.log(r)
            axios.request({
                url     : encodeURI(r),
                method  : 'GET',
                headers : {
                    'Content-Type' : 'application/json'
                },
                // data    : JSON.stringify(info)
            }).then(
                res => {
                    // console.log('res ---->>',res.data) ;
                    // console.log('res ---->>',Array.isArray(res.data)) ;
                    if (Array.isArray(res.data)){
                        if (res.data.length === 0) {
                            console.log('No se encontraron datos') ;
                            commit('setExistePaciente',false)
                            commit('setAskAddPaciente',true)
                            commit('setPaciente',{})
                        }else if (res.data.length === 1) {
                            commit('setPaciente',res.data[0])
                        }else {
                            commit('setPacientes',res.data) ;
                        }
                    }else{
                        commit('setPaciente',res.data)
                    }
                }
            )
        },
        
        // salva a los pacientes 
        savePacienteFromCapture({getters,state,commit}){
            const r = `${process.env.VUE_APP_API_ENDPOINT}expedientes`
            return new Promise((resolve,reject) => {
                if ('generales' in state.captures){
                    axios.request({
                        url     : r,
                        method  : 'POST',
                        headers : {
                            'Content-Type' : 'application/json'
                        },

                        data : JSON.stringify(Object.assign({},state.captures.generales, { _fields : getters.getInstrumento.preguntas }))
                    }).then(
                        res => {
                            if (res.status === 200){
                                commit('addPaciente',res.data) ;
                                commit('searchPacienteById',getters.getPacientes.length-1)
                                commit('setAskAddPaciente',false)
                                commit('resetCapture','generales')
                                resolve()
                            }else{
                                reject()
                            }
                        }
                    )
                }else{
                    reject()
                }
            })
        },

        savePacienteTransactions({state, getters},capture){
            const id    = getters.getPaciente._id
            const data  = Object.assign( {}, state.captures[capture.nombre] , { tipo_ : capture.nombre }, { paciente : id } )
            const r = `${process.env.VUE_APP_API_ENDPOINT}consultas`
            
            return new Promise((resolve) => {
                if (capture._id)
                    axios.request({
                        url     : r,
                        method  : 'POST',
                        data    : JSON.stringify(data),
                        headers : {
                            'Content-Type' : 'application/json'
                        }
                    }).then((res)=> resolve(res))                    
                else
                    axios.request({
                        url     : r,
                        method  : 'POST',
                        data    : JSON.stringify(data),
                        headers : {
                            'Content-Type' : 'application/json'
                        }
                    }).then((res)=> resolve(res))
            })
        },

        updatePacienteTransactions({state},capture){
            const data  = Object.assign( {}, 
                { 
                    data      : state.captures[capture.grupo] , 
                    seccion   : capture.grupo 
                }
            ) ;
            const r = `${process.env.VUE_APP_API_ENDPOINT}consultas/${capture._id}` ;
            
            return new Promise((resolve) => {
                // if (capture._id)
                    axios.request({
                        url     : r,
                        method  : 'PUT',
                        data    : JSON.stringify(data),
                        headers : {
                            'Content-Type' : 'application/json'
                        }
                    }).then((res)=> resolve(res))                    
            })
        },

        loadConsultas({commit},fecha){
            const r = `${process.env.VUE_APP_API_ENDPOINT}loadCitas/${fecha}`
            return new Promise((resolve, reject) => {
                axios.request({
                    url     : r,
                    method  : 'GET',
                    headers : {
                        'Content-Type' : 'application/json'
                    },
                }).then(
                    res => {
                        if (res.status === 200){
                            commit('setConsultas', res.data) ;
                            resolve() ;
                        }else{
                            reject()
                        }
                    }
                )
            })
        },
        sendConsulta2Historial(context,id){
            const r = `${process.env.VUE_APP_API_ENDPOINT}expedientes/${id}` ;
            return new Promise((resolve, reject) => {
                axios.request({
                    url     : r,
                    method  : 'PUT',
                    headers : {
                        'Content-Type' : 'application/json'
                    },
                }).then(
                    res => {
                        if (res.status === 200){
                            console.log('sendConsulta2Historial', res.data) ;
                            resolve(res.data) ;
                        }else{
                            reject()
                        }
                    }
                )
            }) ;
        },
        saveCapturaInstrumento:({state,commit,getters},ancestro) => {
            console.log('getters.getUsuarioProyecto.params.table',getters.getUsuarioProyecto)
            const param = { 
                // table : getters.getDestCollection , 
                // table   : 'datos',
                ancestro    : ancestro,
                info        : Object.assign({  }, state.captura,{ _type : 'doc', _instrumento : state.instrumento })
            }
            
            if (("params" in getters.getUsuarioProyecto)  &&
                ("table" in getters.getUsuarioProyecto.params))
                param.table  = getters.getUsuarioProyecto.params.table

            return new Promise((resolve, reject) => {
                axios.request({
                    method  : 'POST',
                    url     : `${process.env.VUE_APP_API_ENDPOINT}cadaveres`,
                    data    : JSON.stringify(param),
                    headers : {
                        'Content-Type': 'application/json',
                    }
                }).then(
                    (info) => {
                        commit('addUsuarioActivity',info.data)
                        resolve(info.data)
                    }
                ).catch( error => reject(error))
            })
        },
        loadProyectos({commit},uid){
            console.log('loadProyectos 2',uid)
            console.log('loadProyectos 2', `${process.env.VUE_APP_API_ENDPOINT}proyectos`)
            return new Promise((resolve,reject) => {
                axios.request({
                    url     : `${process.env.VUE_APP_API_ENDPOINT}proyectos`,
                    method  : 'GET',
                    headers : {
                        'Content-Type' : 'application/json'
                    }
                }).then(
                    (res) => {
                        if (res.status === 200){
                            res.data.forEach(e => {
                                const pr = Object.assign({}, e, { fecha : new Date( ) })
                                console.log('loading proyecto',pr)
                                commit('addProyecto', pr)
                            })
                            resolve() ;
                        }else{
                            reject() ;
                        }
                    }
                )
            })
        },
        loadProyecto:({commit},proyecto) => {
            console.log('loadProyecto',proyecto)
            console.log('loadProyecto',`${process.env.VUE_APP_API_ENDPOINT}proyectos/${proyecto}`)
            return new Promise((resolve, reject) => {
                axios.request({
                    url     : `${process.env.VUE_APP_API_ENDPOINT}proyectos/${proyecto}`,
                    method  : 'GET',
                    headers : {
                        'Content-Type' : 'application/json'
                    }
                }).then(
                    (res) => {
                        if (res.status === 200){
                            commit('updateProyecto',Object.assign({},res.data, { fecha : new Date() }))
                            // commit('addProyecto',Object.assign({},res.data, { fecha : new Date() }))
                            console.log('addProyecto', res.data) 
                            resolve()
                        }else{
                            reject()
                        }
                    }
                )
            })
        },
        loadUsuarios({commit}){
            return new Promise((resolve, reject) => {
                axios.request({
                    url     : `${process.env.VUE_APP_API_ENDPOINT}usuarios`,
                    method  : 'GET',
                    headers : {
                        'Content-Type' : 'applicationjson'
                    },
                }).then(
                    res => {
                        if (res.status === 200){
                            res.data.forEach(e => commit('addUsuario',e))
                            resolve()
                        }else{
                            reject()
                        }
                    }
                )
            })
        },
        loadUsuarioActivity({getters,commit}, info){
            commit('setUsuarioProyecto',info.path)
            
            const u = `${process.env.VUE_APP_API_ENDPOINT}proyectosusuario`
            const d = Object.assign({}, getters.getUsuarioProyecto.params, { usuario : getters.getUser.uid })
            console.log('cargando la informacion del proyecto') ;
            console.log('loadUsuarioActivity',u) ;
            console.log('loadUsuarioActivity',d) ;
            // if (getters.getUsuarioProyecto.loadData){
                return new Promise((resolve, reject) => {
                    axios.request({
                        url     : u,
                        method  : 'GET',
                        headers : {
                            'Content-Type' : 'application/json'
                        },
                        data : JSON.stringify(d)
                    }).then((res) => {
                        console.log('respuesta',res) ;
                        if (res.status === 200){
                            commit("setUsuarioActivity",res.data) ;
                            resolve()
                        }else{
                            console.log(res)
                            reject()
                        }
                    }).catch(error => console.log('error en la carga de actividades',error))
                })
            // }
        },
        loadUsuario({getters, commit}){
            return new Promise((resolve, reject) => {
                axios.request({
                    url     : `${process.env.VUE_APP_API_ENDPOINT}usuarios/${getters.getUser.uid}`,
                    method  : 'GET',
                    headers : {
                        'Content-Type' : 'application/json'
                    }
                }).then(res => {
                    if (res.status === 200){
                        console.log('setUsuario',res.data)
                        commit('setUsuario',res.data) 
                        resolve(res.data)
                    }else{
                        reject()
                    }
                })
            })
        },

        createProyecto({commit}, info){
            axios.request({
                url     : `${process.env.VUE_APP_API_ENDPOINT}proyectos`,
                method  : 'POST',
                headers : {
                    'Content-Type' : 'application/json'
                },
                data : JSON.stringify(info)
            }).then(
                res => {
                    commit('addProyecto',res.data) ;
                }
            )
        },
        updateProyecto({commit}, info){
            return new Promise((resolve) => {
                axios.request({
                    url     : `${process.env.VUE_APP_API_ENDPOINT}proyectos/${info._id}`,
                    method  : 'PUT',
                    headers : {
                        'Content-Type' : 'application/json'
                    },
                    data : JSON.stringify(info)
                }).then(
                    res => {
                        commit('updateProyecto',res.data) ;
                        resolve()
                    }
                )
            })
        },
        insertInstrumento({commit}, data){
            return new Promise((resolve,reject) => {
                axios.request({
                    // url     : `${process.env.VUE_APP_API_ENDPOINT}proyectos/${data.proyecto}/instrumento`,
                    url     : `${process.env.VUE_APP_API_ENDPOINT}proyectos`,
                    method  : 'POST',
                    headers : {
                        'Content-Type' : 'application/json'
                    },
                    data : JSON.stringify(data)
                }).then(
                    res => {
                        console.log('isertaInstrumento',res)
                        if (res.status === 200){
                            commit('addInstrumento',res.data)
                            resolve()
                        }else{
                            reject()
                        }
                    }
                )
            })
        },
        updateInstrumento({commit}, data){
            return new Promise((resolve,reject) => {
                axios.request({
                    url     : `${process.env.VUE_APP_API_ENDPOINT}proyectos/${data.proyecto}`,
                    method  : 'PUT',
                    headers : {
                        'Content-Type' : 'application/json'
                    },
                    data : JSON.stringify(data.info)
                }).then(
                    res => {
                        console.log('updateInstrumento',res)
                        if (res.status === 200){
                            commit('addInstrumento',res.data)
                            resolve()
                        }else{
                            reject()
                        }
                    }
                )
            })
        },
        updatePregunta({commit, getters},datos){
            const proyecto       = getters.getProyecto._id ;
            // const instrumento    = getters.getInstrumento._id ;
            console.log('UPdating pregunta') ;
            return new Promise((resolve, reject) => {
                axios.request({
                    url     : `${process.env.VUE_APP_API_ENDPOINT}proyectos/${proyecto}`,
                    method  : 'PUT',
                    data    : JSON.stringify(datos.info),
                    headers : {
                        'Content-Type' : 'application/json'
                    }
                }).then(
                    res => {
                        console.log('Pregunta actualizada',res)
                        if (res.status === 200){
                            commit('updateInstrumento',res.data)
                            // commit('setPregunta', datos.info ) ;
                            resolve()
                        }else{
                            reject()
                        }
                    }
                )
            })
        },
        appendPregunta({commit, getters},datos){
            const proyecto       = getters.getProyecto._id ;
            const instrumento    = getters.getInstrumento._id ;
            console.log('Appending pregunta') ;
            return new Promise((resolve, reject) => {
                axios.request({
                    url     : `${process.env.VUE_APP_API_ENDPOINT}proyectos`,
                    method  : 'POST',
                    data    : JSON.stringify({
                        info        : datos.info,
                        proyecto    : proyecto,
                        instrumento : instrumento
                    }),
                    headers : {
                        'Content-Type' : 'application/json'
                    }
                }).then(
                    res => {
                        console.log('Pregunta agreregada',res.data)
                        if (res.status === 200){
                            // commit('addPregunta',res.data)
                            commit('updateInstrumento',res.data)
                            commit('addPregunta',datos.info)
                            resolve()
                        }else{
                            reject()
                        }
                    }
                )
            })
        },
        // appendRespuestas({commit},info){
        appendRespuestas({},info){
            const r = `${process.env.VUE_APP_API_ENDPOINT}proyectos`
            return new Promise((resolve, reject) => {
                axios.request({
                    url     : r,
                    method  : 'POST',
                    data    : JSON.stringify(info),
                    headers : {
                        'Content-Type' : 'application/json'
                    }
                }).then(res => {
                    if (res.status === 200){
                        // commit('addRespuestas', { pregunta : info.pregunta, data : res }) ;
                        resolve()
                    }else{
                        reject()
                    }
                })
            })
        },

        updateRespuestas({}, info){
            const r = `${process.env.VUE_APP_API_ENDPOINT}proyectos/${info.pregunta}`
            return new Promise((resolve, reject) => {
                axios.request({
                    url     : r,
                    method  : 'PUT',
                    data    : JSON.stringify(info),
                    headers : {
                        'Content-Type' : 'application/json'
                    }
                }).then(res => {
                    if (res.status === 200){
                        // commit('addRespuestas', { pregunta : info.pregunta, data : res }) ;
                        resolve()
                    }else{
                        reject()
                    }
                })
            })
        },
        /**
         * carga los instrumentos q tengan un determinado tipo de instrumento
         */
        loadInstrumentosPadres({commit},padre){
            return new Promise((resolve, reject) => {
                axios.request({
                    url     : `${process.env.VUE_APP_API_ENDPOINT}cadaveres/${padre}`,
                    method  : 'GET',
                    headers : {
                        'Content-Type' : 'application/json'
                    }
                }).then(
                    res => {
                        console.log('Pregunta agreregada',res.data)
                        if (res.status === 200){
                            // commit('addPregunta',res.data)
                            commit('setInstrumentosHeader',res.data)
                            resolve(res.data)
                        }else{
                            reject()
                        }
                    }
                )
            })
        },
        //**************************************************************************************** */
        deleteCita(context,dni){
            console.log('borrandocita',dni)
            db.collection('pacientes').doc(dni).update({
                cita_ : firebase.firestore.FieldValue.delete()
            })
        },
        
        savePacienteTransactionsFB({state, getters},capture){
            // const id = (state.paciente && (typeof state.paciente === 'object') && ('id_' in state.paciente))?state.paciente.id_:capture.dni
            const id = getters.getPacienteIdx.id_
            const data = state.captures[capture.nombre] 
            return new Promise((resolve) => {
                if (capture.id_)
                    db.collection('pacientes').doc(id).collection(capture.grupo).doc(capture.id_).set({ [capture.nombre] : data}, {merge : true}).then(() => resolve())
                else
                    db.collection('pacientes').doc(id).collection(capture.grupo).add({ [capture.nombre] : data}).then(() => resolve())
            })
        },
        savePacienteSubsFB({state},capture){
            const data = { [`${capture.grupo}`] : { [`${capture.nombre}_`] : state.captures[capture.nombre] }}
            const id = (state.paciente && (typeof state.paciente === 'object') && ('dni' in state.paciente))?state.paciente.dni:capture.id
            return new Promise((resolve) => {
                db.collection('pacientes').doc(id).set( data ,{ merge : true }).then(
                    () => resolve()
                )
            })
        },
        savePacienteFB({state}){
            return new Promise((resolve) => {
                db.collection('pacientes').doc(state.paciente.dni).set(state.paciente ,{ merge : true }).then(
                    () => {
                        resolve() 
                    }
                )
            })
        },
        loadApps({commit}){
            return new Promise((resolve) => {

                db.collection('apps').get().then(
                    querySnapShot => {
                        querySnapShot.forEach(doc => {
                            commit('addApp',Object.assign({},{ id_ : doc.id } ,doc.data()))
                        })

                        resolve()
                    }
                )
            })
        },
        loadInit({dispatch}){
            // dispatch('loadConsultas')
            
            dispatch('loadMenu')
            dispatch('loadApps')
            dispatch('loadUserData') 
            dispatch('loadUsuarios') 
            // dispatch('loadConsultas', new Date().toISOString().slice(0, 10)) ;
            // console.log('consultas',state.consultas) ;
            dispatch('loadLocationData') ;
        },
        
        loadUserData({state, commit, dispatch}){
            db.collection('users').doc(state.user.uid).onSnapshot(
                async snapshot => {
                    if (snapshot.exists){
                        const info = snapshot.data() ;
                        const config = await info.config.get() ;
                        console.log('info',info)
                        console.log('config',config.data()) 
                        const r = Object.assign({}, info, { config : config.data() }) 
                        console.log('setUserData',r) ;
                        commit('setUserData',snapshot.data()) ;
                    }
                }
            )
            dispatch('loadUsuario') ;
        },
        loadMenu({commit}){
            db.collection('menu').doc('items').onSnapshot(
                onSnapshotQuery => {
                    if (onSnapshotQuery.exists){
                        commit('setMenu',onSnapshotQuery.data())
                        console.log('onSnapshotQuery.data()',onSnapshotQuery.data())
                    }
                }
            )
        },
        updateCategoriasProy({},info){
            console.log('updateCategoriasProy',`${process.env.VUE_APP_API_ENDPOINT}addCategoria/${info.id}`)
            return new Promise((resolve, reject) => {
                axios.request({
                    url     : `${process.env.VUE_APP_API_ENDPOINT}addCategoria/${info.id}`,
                    method  : 'POST',
                    headers : {'Content-Type' : 'application/json'},
                    data    : JSON.stringify(info.info)
                }).then(
                    res => {
                        console.log()
                        if (res.status === 200){
                            // commit('addGeoreferencias', res.data) ;
                            resolve()
                        }else{
                            reject() 
                        }
                    }
                )
            })
        },
        /**
         * carga todos los registros q tengan en su contenido informacion referente a gps
         */
        loadLocationData({commit}){
            return new Promise((resolve, reject) => {
                axios.request({
                    url     : `${process.env.VUE_APP_API_ENDPOINT}georeferencias`,
                    method  : 'GET',
                    headers : {'Content-Type' : 'application/json'},
                    
                }).then(
                    res => {
                        if (res.status === 200){
                            commit('addGeoreferencias', res.data) ;
                            resolve()
                        }else{
                            reject() 
                        }
                    }
                )
            })
        },
        appendConsulta({commit,state},data){
            db.collection('pacientes').doc(state.paciente.dni).set({ cita_ : data }, { merge : true }).then(
                () => {
                    commit('setPaciente',{})
                }
            )
        },
        buscar({commit},dni) {
            commit('setExistePaciente',true)
            return new Promise((resolve) => {
                db.collection('pacientes').doc(dni).get().then(
                    doc => {
                        commit('setExistePaciente',doc.exists)
                        commit('setAskAddPaciente',!doc.exists)
                        commit('setPaciente',{})
                        var res = {}
                        if (doc.exists){
                            res = Object.assign({},{ id_ : doc.id }, doc.data() )
                            Object.keys(res).forEach(element => {
                                if (res[element] instanceof firebase.firestore.Timestamp)
                                    res[element] = res[element].toDate()
                            })
                            console.log('setPaciente',res)
                            commit('setPaciente',res)
                        }
                        resolve(res)
                    }
                )
            })
        },
        registarUsuario(context,info){
            const id = info.id
            delete info.id
            db.collection('users').doc(id).set( info, { merge :true })
        },
        salvaImagen(context, info){
            return new Promise((resolve, reject) => {
                if (!info.dni)
                    reject()
                else
                    storage.ref().child(`/empleados/${info.archivo.name}`).put(info.archivo).then(
                        () => resolve()
                    )
            })
        },
        buscarDocFB:({commit},param) => {
            return new Promise((resolve) => {
                const preg = Object.keys(param)[0]
                console.log('preg >>>',preg)
                db.collection('epi-capturas').where(preg,'==',param[preg]).get().then(
                    docs => {
                        docs.forEach(doc => {
                            const dato = Object.assign({}, doc.data(), { id_ : doc.id } )
                            Object.keys(dato).forEach(campo => {
                                if (!campo.endsWith('_')){
                                    if (dato[campo] instanceof firebase.firestore.Timestamp){
                                        console.log('dato[campo]',dato[campo])
                                        dato[campo] = new Date(dato[campo].seconds*1000)}
                                }
                            })
                            commit('setCaptura', dato )
                            resolve()
                        })
                    }
                ) ;
            })
        },
        saveCapturaFB:({state,commit}) => {
            const tempo = Object.assign({}, state.captura)
            delete tempo.id_
            // db.collection('epi-capturas').doc(state.captura.id_).set(
            //     Object.assign({  },tempo,{ type_ : 'doc', instrumento_ : state.instrumento }),
            //     { merge : true }
            // )
            const data = Object.assign({  },{ 
                data_ : tempo, 
                type_ : 'doc', 
                id_ : state.instrumento.id, 
                instrumento_ : state.instrumento 
            })
            return new Promise((resolve) => {
                db.collection(`${state.instrumento.id}`).add(data).then(
                    (id) => {
                        // dispatch('saveCaptura')
                        commit('setCaptura',{})
                        resolve(id)
                    }
                ).catch(
                    e => console.log('error',e)
                )
            })
        },
        loadProyectoFB:({commit},proyecto) => {
            console.log('loadProyectoFB',proyecto)
            return new Promise((resolve) => {
                db.collection('epi-proyectos').doc(proyecto).get().then(
                    doc => {
                        const pr = Object.assign({}, doc.data(), { id_ : doc.id, fecha : new Date( ) })
                        console.log('loading proyecto',pr)
                        commit('addProyecto', pr)
                        resolve()
                    }
                )
            })
        },
        loadProyectosFB:({commit},uid) => {
            console.log('loadProyectoFB',uid)
            return new Promise((resolve) => {
                db.collection('epi-proyectos').get().then(
                    docs => {
                        docs.forEach( doc => {
                            const pr = Object.assign({}, doc.data(), { id_ : doc.id, fecha : new Date( ) })
                            // const pr = Object.assign({}, doc.data(), { id_ : doc.id, fecha : new Date( doc.data().fecha.seconds*1000) })
                            console.log('loading proyecto',pr)
                            commit('addProyecto', pr)
                        })
                        resolve()
                    }
                )
            })
        },
        buscarDoc:({commit},param) => {
            return new Promise((resolve, reject) => {
                axios({
                    method  : 'POST',
                    url     : `${process.env.VUE_APP_API_ENDPOINT}epi-data/_search`,
                    data    : JSON.stringify({
                        size : 1000,
                        query: {
                            term : param
                        }
                    }),
                    headers : {
                        'Content-Type': 'application/json',
                    }
                }).then(
                    res => {
                        if (res.data.hits.hits.length === 1){
                            commit('setCaptura',Object.assign({ id_ : res.data.hits.hits[0]._id },res.data.hits.hits[0]._source))
                            resolve()
                        }
                }).catch( error => reject(error))
            })
        },

        /**
         * elemina informacion de los archivos cargados de KOBOS
         */
        
        deleteData({commit},info){
            return new Promise((resolve) => {
                commit('deleteInformacion',info) ;
                resolve()
            })
        },
        loadFromServerProyecto({commit},proyecto){
            const r = `${process.env.VUE_APP_API_ENDPOINT}sequia?proyecto=${proyecto}` ;
            return new Promise((resolve, reject) => {
                axios.request({
                    url     : r,
                    method  : 'GET',
                    headers : {
                        'Content-Type' : 'application/json'
                    }
                }).then (res => {
                    if (res.status === 200){
                        console.log('loadFromServerProyecto',Object.assign({ fromDB : true},res.data)) ;
                        commit('addDatasets',Object.assign({ fromDB : true},res.data))
                        resolve(res.data)
                    }
                }),

                error => reject(error)
            })
        },
        loadFromServerHeaders({commit}){
            return new Promise((resolve) => {
                const r = `${process.env.VUE_APP_API_ENDPOINT}sequia` ;
                axios.request({ 
                    url     : r,
                    method  : 'GET',
                    headers : {
                        'Content-Type' : 'application/json'
                    }
                }).then(
                    res => {
                        if (res.status === 200){
                            // res.data.forEach(element => {
                            //     console.log('element',element)
                            //     // commit('addProyectosLista',element)
                            // })
                            resolve(res.data)
                        }
                    },
                    error => console.log('error',error)
                )
            })
        }
    }
})


/**
 sendConsulta2Historial(context,capture){
    db.collection(capture.path).doc(capture.id_).get().then(
            res => {
                if (res.exists){
                    var path = capture.path.split('/')
                    path[path.length -1] = 'historial'
                    db.collection(path.join('/')).add(res.data()).then(() => {
                        db.collection(capture.path).doc(capture.id_).delete()
                    })
                }
            }
        )
    },
 */