<template>
    <div class="p-grid p-col-12">
        <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
                <i v-if="(closed)">{{componente.min}}</i>
                <i class="pi pi-chevron-right" v-if="(closed)"></i>
                <i class="pi pi-chevron-left" v-if="(!closed) && (data <= componente.min)"></i>
                <i class="pi pi-chevron-right" v-if="(!closed) && (data > componente.min)"></i>
            </span>
            <InputText placeholder="Price" v-model="data" @input="modificado"/>
            <span class="p-inputgroup-addon">
                <i class="pi pi-chevron-left" v-if="(closed)"></i>
                <i v-if="(closed)">{{componente.max}}</i>
                <i class="pi pi-chevron-left" v-if="(!closed) && (data <= componente.max)"></i>
                <i class="pi pi-chevron-right" v-if="(!closed) && (data > componente.max)"></i>
            </span>
        </div>
        <div class="p-col-12" v-if="error">{{componente.error}}</div>
    </div>
</template>

<script>
export default {
    props : {
        componente : {
            type        : Object,
            default     : () => {},
            required    : true
        },
        closed : {
            type        : Boolean,
            default     : () => true
        },
        value : {
            type    : Number,
            default : () => 10
        }
    },
    data(){
        return {
            data : this.value
        }
    },
    methods: {
        modificado() {
            if (this.closed && ((Number(this.data) > this.componente.max ) || (Number(this.data) < this.componente.min ))){
                console.log('Number(this.data) > this.componente.max',Number(this.data) > this.componente.max)
                console.log('Number(this.data) > this.componente.max',Number(this.data) )
                console.log('Number(this.data) > this.componente.max',this.componente.max)
                if (Number(this.data) > this.componente.max){
                    this.data = this.componente.max
                }
                
                console.log('(Number(this.data) < this.componente.min)',(Number(this.data) < this.componente.min))
                console.log('(Number(this.data) < this.componente.min)',(Number(this.data) ))
                console.log('(Number(this.data) < this.componente.min)', this.componente.min)
                if(Number(this.data) < this.componente.min){
                    this.data = this.componente.min
                }
                // this.$forceUpdate()
            }
            
        }
    },
    computed: {
        error(){
            return (this.closed && ((this.data > this.componente.max ) || (this.data < this.componente.min )))

        }
    },
}
</script>

<style>

</style>