<template>
    <div :class="'p-col-'.concat(width)">
        <!-- {{ multi }} -->
        <div class="p-col-12">{{title}}</div>
        <DataView :value="opciones" :layout="layout">
            <template #grid="slotProps">
                <div :class="'p-col-'.concat(opciones.length>4?3:12/opciones.length)">
                    <!-- {{ opciones.length>4?3:12/opciones.length }} -->
                    <ToggleButton 
                        v-model="data[slotProps.data.valor]" 
                        :onLabel="slotProps.data.etiqueta" :offLabel="slotProps.data.etiqueta" 
                        onIcon="pi pi-check" offIcon="pi pi-times" 
                        class="w-full" @click="seleccion(slotProps.data)"/>
                </div>
            </template>
            <template #list="slotProps">
                <div class="p-col-12">
                    <ToggleButton 
                        v-model="data[slotProps.data.valor]" 
                        :onLabel="slotProps.data.etiqueta" 
                        :offLabel="slotProps.data.etiqueta" 
                        onIcon="pi pi-check" offIcon="pi pi-times" 
                        class="w-full" @click="seleccion"/>
                </div>
            </template>
        </DataView>
    </div>
</template>

<script>
Array.prototype.chunk = function ( n ) {
    if ( !this.length ) {
        return [];
    }
    return [ this.slice( 0, n ) ].concat( this.slice(n).chunk(n) );
};

export default {
    props : { 
        icon : String,
        title : {
            type        : String,
            required    : true
        },
        field : {
            type        : String,
            required    : true

        },
        opciones : {
            type        : Array,
            required    : true
        },
        optionLabel     : String,
        value : {
            type        : [Object, Array],
            default     : () => { return [] }
        },
        width   : {
            type    : Number,
            default : () => 12
        },
        layout  : {
            type    : String,
            default : () => 'grid'
        },
        multi   : {
            type    : Boolean,
            default : () => true
        }
    },
    data() {
        return {
            // data : this.value
            data    : {},
            objeto  : []
        }
    },
    mounted() {
        console.log('mounted >>>>>>',this.value)
        // ver en caso de single
        this.opciones.forEach( v => {
            this.data[v.valor] = false ;
        })

        if (this.multi){
            this.value.forEach( v => {
                this.data[v.valor] = true
            })
        }else{
            this.data = this.value
        }

        console.log('this.data ',this.data)
    },
    methods: {
        seleccion(value){
            // se cargan las selecciones en un arreglo
            if (this.multi){
                if (this.data[value.valor]){
                    this.objeto.push(value)
                }else{
                    const i = this.objeto.findIndex( i => i.valor === value.valor )
                    this.objeto.splice(i,1);
                }
            }else{
                // son respuestas individuales
                if (this.data[value.valor]){
                    this.objeto = value ;
                    Object.keys(this.data).filter(f=>f !== value.valor).forEach( t => this.data[t] = false )
                }else{
                    this.objeto = null
                }
            }
            
            this.$emit('modified',this.objeto)
            // console.log('value.etiqueta',value)
        }
    },
    
}
</script>

<style>

</style>