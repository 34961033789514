<template>
    <div class="p-col-12 p-grid p-fluid p-m-t-3" style="text-align:left">  
        <!-- <div style="text-align:left" :class="`p-col-${width}`" v-for="pregunta in preguntas" :key="pregunta"> -->
        <template v-for="pregunta in preguntas" :key="pregunta">
            <component 
                :disabled="existePaciente"
                :width="12"
                :required="pregunta.required"
                :is="'custom-'+pregunta['field-type'].value" 
                :field="pregunta['field-name']"
                :ffield="pregunta.contenido.map(m=>m.field)"
                :title="pregunta.pregunta"
                :ftitle="pregunta.contenido.map(m=>m.title)"
                :opciones="pregunta.contenido?pregunta.contenido:[]"
                optionLabel="etiqueta"
                :multi="pregunta['field-type'].multi"
                :isSearch="pregunta.searchField"
                :value="data[pregunta['field-name']]"
                :icon="pregunta.searchField"
                @modified="procesaCampo($event,pregunta['field-name'])"
                @keyenter="buscar($event,pregunta)"
                @focusout="desenfoco(pregunta)"
            />
        </template>
        <div 
            class="p-offset-6 p-col-6 p-xl-offset-10 p-xl-2 p-lg-offset-10 p-lg-2" 
            style="text-align:right;align-content:right"
            v-if="showActions">
            <span class="p-buttonset">
                <Button icon="pi pi-save" @click="salvar" />
                <Button icon="pi pi-times" @click="cancelar" />
            </span>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

import { mapGetters } from 'vuex'
export default {
    data(){
        return {
            data : {}
        }
    },
    props: {
        instrumento : {
            type    : String,
            require : true
        },
        showActions : {
            type    : Boolean,
            default : () => true
        },
        path        : {
            type    : Array,
            default : () => []
        },
        width       : {
            type    : Number,
            default : () => 12
        },
        info        : {
            type    : Object,
            // default : () => Object.create({}),
            default : () => null,
        }
    },
    watch: {
        paciente(ol){
            var BreakException = {}
            var tempo = ol
            if (ol && this.path && (this.path.length > 0)){
                try{
                    this.path.forEach(p => {
                    if (p in tempo)
                        tempo = tempo[p]
                    else{
                        throw BreakException
                    }
                })
                this.data = tempo
                }catch (e){
                    if (e !== BreakException )
                        throw e
                    else
                        this.data = {}
                }
            }
            else{
                this.data = {}
                this.preguntas.forEach(preg => {
                    // console.log(`${preg['field-name']}`,'prueba de cambio de datos')
                    this.data[preg['field-name']] = null
                })
            }
        }
    },
    mounted() {
        var BreakException = {}
        var tempo = this.paciente
        if (this.paciente && this.path && (this.path.length > 0)){
            try{
                this.path.forEach(p => {
                if (p in tempo)
                    tempo = tempo[p]
                else{
                    throw BreakException
                }
            })
            this.data = tempo
            }catch (e){
                if (e !== BreakException )
                    throw e
                else
                    this.data = {}
            }
        }
        else{
            this.data = {}
            this.preguntas.forEach(preg => {
                // console.log(`${preg['field-name']}`,'prueba de cambio de datos')
                this.data[preg['field-name']] = null
            });

            if (this.info !== null){
                this.info._fields.forEach(e => {
                    this.data[e['field-name']] = this.info[e['field-name']] ;
                })
            }
        }
    },
    methods: {
        cancelar(){
            this.data = {}
            this.$emit('cancel')
        },
        salvar(){
            const r = this.instrumento.split('.')
            this.$store.commit('setCapture',{ capture : r[r.length -1], data : Object.assign({},this.data,{ _fields : this.preguntas }) })  
            // this.data = {}
            this.preguntas.forEach(preg => {
                this.data[preg['field-name']] = null
            })
            this.$emit('saveShowInstrumento',r[r.length -1])
        },
        procesaCampo(evt,campo){
            this.data[campo] = moment.isDate(evt)?new Date(evt):evt
        },
        buscar(evt,preg){
            if (preg.searchField)
                this.$store.dispatch('buscar',evt )
        },
        desenfoco(preg){
            if (preg.searchField && (this.data[preg['field-name']]) && (this.data[preg['field-name']].length === 13))
                this.buscar(this.data[preg['field-name']], preg)
        }
    },
    computed: {
        ...mapGetters({
            proyectos       : 'getProyectos',
            paciente        : 'getPacienteIdx',
            // paciente        : 'getPaciente',
            existePaciente  : 'existPaciente',
            proyecto        : 'getProyecto',
        }),
        preguntas(){
            return (this.componente && this.componente.contenido)?this.componente.contenido:[]
            // return (this.componente && this.componente.preguntas)?this.componente.preguntas:[]
        },
        componente(){
            var ret = []
            if (this.info !== null){
                ret = Object.create({ preguntas : this.info._fields }) ;
            }else{
                const res = this.instrumento.split('.')
                // if (this.proyectos && this.proyectos.length > 0){
                //     if (res.length === 2){
                //         ret = this.proyectos.filter( f => f._id === res[0] )
                //         ret = ret[0].instrumentos.filter( f => f.id === res[1] )
                //         if (ret.length === 1)
                //             ret = ret[0]
                //     }
                // }
                if (this.proyecto && this.proyecto.contenido){
                    // console.log('this.proyecto CuestomShowInstrumento',this.proyecto);
                    // console.log('this.instrumento CuestomShowInstrumento',this.instrumento);
                    if (res.length === 2){
                        ret = this.proyecto.contenido.filter( f => f._id === res[1] )
                        if (ret.length === 1)
                            ret = ret[0]
                    }
                }

            }
            console.log('this.componente CuestomShowInstrumento',ret);
            return ret ;
        }
    },
}
</script>

<style>

</style>
