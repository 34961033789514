<template>
    <div class="p-field p-col-12 p-grid p-fluid">
        <span :class="(icon?'p-input-icon-left':'')">
            <div class="p-grid p-col-12">
                <label :for="field" class="p-col-2">{{title}}</label> 
                <div class="p-col-10">
                    <Checkbox v-model="todos" value="todos" @change="selector"/>
                    <label> Todos</label>
                </div>
            </div>
            <div class="p-col-12 p-xl-3 p-lg-4">
                <!-- <div class="p-col-12">{{title}}</div> -->
                <SelectButton
                    :id="field"
                    v-model="data"
                    :options="opciones"
                    :optionLabel="optionLabel"
                    :multiple="true"
                    @change="seleccion"
                />
            </div>
        </span>
    </div>
</template>

<script>
export default {
    props : { 
        title       : String,
        field       : String,
        opciones    : {
            type    : Array,
            required: true
        },
        optionLabel : String,
        icon        : String,
        value       : {
            type : Array,
            default : () => { return [] }
        }
    },
    data() {
        return {
            todos :[],
            data : this.value
        }
    },
    watch: {
        value(niu){
            this.data = niu
        }
    },
    methods: {
        cambio(){
        },
        seleccion(evt){
            this.$emit('modified',evt.value)
        },
        selector(){
            if (Object.keys(this.todos).length === 0)
                this.data.splice(0, this.data.length)
            else
                this.data.push.apply( this.data, this.opciones )
        }
    },
}
</script>

<style>

</style>