<template>
    <div class="p-col-12 p-grid p-fluid">
        <div class="p-inputgroup">
            <Button icon="pi pi-eye" @click="buscar" v-if="Object.keys(paciente).length > 0" />
            <InputText 
                v-model="data" 
                :disabled="buscando"
                :placeholder="!floatTitle?title:''"
                :id="field" 
                :type="type" 
                @input="modified" 
                @keyup="key" x-webkit-speech/>            
            <Button icon="pi pi-search" @click="buscar"/>
        </div>
        <div class="p-col-12 p-fluid p-grid" v-if="showDetail">
            <display-info-searched instrumento="personal-COPECO.generales" path=""/>
        </div>
        
        <Sidebar :visible="askAddPaciente" position="bottom" style="height:75vh" :showCloseIcon="false">
            <div class="p-col-12">
                <Button icon="pi pi-times" @click="$store.commit('setAskAddPaciente',false)" />
                <Button icon="pi pi-save" @click="salvar" />
            </div>
            <input-generales :info="datosPaciente" ref="inputs"/>
        </Sidebar>


        <Sidebar :visible="pacientes.length > 0" position="right" style="width :75vw" :showCloseIcon="false">
            <div class="p-col-12">
                <Button icon="pi pi-save" @click="cancelaBusqueda" />
                <DataTable :value="pacientes" paginator :rows="5" 
                    @row-click="seleccionaPaciente"
                >
                    <Column field="_id" header="DNI" />
                    <Column field="nombre" header="Nombre" />
                </DataTable>
            </div>
        </Sidebar>
    </div>
</template>

<script>

// 0801200213762
// Sofia Isabella Rodriguez Barahona
            
import { mapGetters } from 'vuex'
export default {
    props : {
        title               : String,
        type                : String,
        field               : String,
        espectedSize        : Number,
        floatTitle          : Boolean,
        blockIfNotPacient   : Boolean,
    },
    data(){
        return {
            data        : null,
            add         : false,
            showPaciente: false ,
            buscando    : false,
            showDetail  : false,
        }
    },
    methods: {
        seleccionaPaciente(evt){
            console.log('evt --->',evt.index)
            this.$store.commit('setPaciente',this.pacientes[evt.index]) ;
            this.$store.commit('setPacientes',[]) ;
        },
        cancelaBusqueda(){
            this.$store.commit('setPacientes',[]) ;
            this.$store.commit('setPaciente',{}) ;
        },
        salvar(){
            console.log('salvar instrumento')
            this.$refs.inputs.salvar()
            this.data = null
            
            this.$store.dispatch('savePacienteFromCapture').then(
                () => this.add = false
            )
        },
        modified(){
            this.$emit('modified')
        },
        key(evt){
            if (this.data.length === this.espectedSize) this.disable = true
            if (evt.key === 'Enter') this.disable = true

            if (this.disable)
                this.$store.dispatch('buscarMG',this.data).then(
                    () => {
                        this.disable = false
                    }
                )
        },
        buscar(){
            this.buscando = true
            console.log('buscarMG',this.data) ;
            this.$store.dispatch('buscarMG',this.data).then(
                () => {
                    this.buscando = false
                    this.data = null                    
                }
            )
        },
        
    },
    computed: {
        ...mapGetters({
            existePaciente  : 'existPaciente',
            paciente        : 'getPaciente',
            capture         : 'getCaptures',
            askAddPaciente  : 'getAskAddPaciente',
            showSearchResult: 'showSearchedInfo',
            pacientes       : 'getPacientes'
        }),
        datosPaciente(){
            return this.paciente || {}
        }
    },
    watch: {
        existePaciente(niu){
            this.showPaciente = (!this.showDetail && niu)
            if (this.askAddPaciente){
                // this.$store.commit('setProyectoById','personal-COPECO')
                this.$store.commit('setInstrumentoById','generales')
            }
        },
    },
}
</script>

<style>

</style>