<template>
    <div class="p-col-12">  
        <div class="p-col-12" v-for="pregunta in componente" :key="pregunta">            
            <div class="p-grid p-fluid" style="text-align:left">
                <div class="p-col-12 p-xl-4 p-lg-4">{{pregunta.pregunta}}</div>
                <template v-if="Array.isArray(data[pregunta['field-name']])">
                    <div style="font-weight:700" :class="'p-col-12 p-xl-8 p-lg-6'.concat(index===0?'':' p-xl-offset-4 p-lg-offset-6')" v-for="(row,index) in data[pregunta['field-name']]" :key="row">
                        <div>{{informacion(row)}}</div>
                    </div>
                </template>    
                <template v-else>
                    <div style="font-weight:700" class="p-col-12 p-xl-8 p-lg-6">{{informacion(data[pregunta['field-name']])}}</div>
                </template>
                <!-- <div style="font-weight:700" class="p-col-12 p-xl-8 p-lg-8">{{informacion((path.length>0)?datos[path][pregunta['field-name']]:datos[pregunta['field-name']])}}</div> -->
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    props: {
        instrumento : {
            type    : String,
            require : true
        },
        info : {
            type    : String,
            require : true 
        },
        path : {
            type    : String,
            require : true,
        },
        datos : {
            type    : Object,
            require : true ,
            default : () => {} 
        }
    },
    methods: {
        informacion(evt){
            var res = evt
            if ((typeof res === 'object' )&&('etiqueta' in res)) res = res.etiqueta
            if ((typeof res === 'object' )&&('seconds' in res)) res = (new Date(res.seconds * 1000)).toLocaleDateString('es-ES',{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
            return res
        }
    },
    computed: {
        ...mapGetters({
            // proyectos   : 'getProyectos',
            // datos       : `getPaciente`,
            // consulta    : 'getConsulta',
        }),
        data(){
            if (!this.datos || this.datos === undefined) return {}
            var res = (this.path.length > 0)?eval(`this.datos${this.path.split('.').map( m => `['${m}']`).join('')}`):this.datos
            if (!res) return {}
            return res 
        },
        componente(){
            const obj = (this.path.length > 0)?this.datos[this.path]:this.datos ;
            const ret = (Object.keys(obj).indexOf('_fields') > 0 )?
                        obj['_fields']:
                        Object.keys(obj).map(m => Object.assign({ orden  : 0, pregunta : m, 'field-name' : m, required : true }));
            ret.sort((a,b) => {
                const ea = ('orden' in a)?Number(a.orden):0 ;
                const eb = ('orden' in b)?Number(b.orden):0 ;
                if (ea < eb) return -1 ;
                if (ea > eb) return 1 ;
                return 0  ;
            }) ;
            return ret ;
        }
    },
}
</script>

<style>

</style>