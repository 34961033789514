<template>
    <div :class="`p-col-${width} p-pt-4`">
        <div class="p-field">

            <div class="p-inputgroup">
                <span class="p-inputgroup-addon">{{data.length}}</span>
                <span class="p-float-label">
                    <InputText id="fechas" disabled v-model="lastDate" :showButtonBar="true" @date-select="cambia"/>
                    <label for="fechas">{{title}}</label>
                </span>
                <Button icon="pi pi-calendar" @click="ver=true"/>
            </div>       
        </div>

        <Dialog :visible="ver" :closable="false" style="width:50vw">
            <div class="p-col-12 p-grid p-fluid">
                <div class="p-field p-col-12" v-for="(item,index) in data" :key="item">
                    <div class="p-inputgroup">
                        <span :class="'p-float-label' + (icon?' p-input-icon-left':'')">
                            <i v-if="icon" class="pi pi-calendar" />
                            <Calendar 
                                v-model="data[index]" 
                                :dateFormat="dateFormat" 
                                :showButtonBar="true" 
                                :view="viewType"
                                @date-select="cambia" />
                            <label>{{title}}</label>
                        </span>
                        <Button icon="pi pi-times" @click="removeIdx(index)"/>
                    </div>
                </div>
                <Button icon="pi pi-times" @click="ver=false"/>
                <Button icon="pi pi-plus" @click="addFecha"/>
            </div>
        </Dialog>
    </div>

</template>


<script>
import moment from 'moment';
export default {
    data(){
        return {
            ver : false,
            data : []
        }
    },
    methods: {
        removeIdx(ind){
            this.data.splice(ind,1)
        },
        cambia(){
            this.$emit('modified',this.data)
        },
        addFecha(){
            if ((this.cantidad > 0) && (this.data.length == this.cantidad))
                return 
            this.data.push(new Date())               
            this.$emit('modified',this.data)
        }
    },
    computed: {
        lastDate(){
            return (this.data.length > 0)?moment(String(this.data[this.data.length -1])).format('YYYY-MM'):null
        }
    },
    props : {
        field : {
            type : String,
            required : true
        },
        width :{
            type : Number,
            default : 12
        },
        title : {
            type : String,
            required : true
        },
        viewType : {
            type : String,
            default: () => 'month'
        },
        dateFormat : {
            type : String,
            default: () => 'yy-mm'
        },
        icon    : String,
        type    : String,
        value   : Array,
        cantidad: Number
    },
}
</script>