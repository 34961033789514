<template>
    <div class="p-col-12">
        <!-- {{ items }} -->
        <DataView layout="list" :value="items" >
            <template #header>
                <div class="p-grid p-grid-nogutter">
                    <div class="p-col-6"></div>
                    <div class="p-col-6">
                        <Calendar id="icon" v-model="fecha" :showIcon="true" />
                    </div>
                </div>
            </template>
            <template #list="slotProps">
                <div class="p-col-12 p-grid" :class="slotProps.data.seleccionado?'seleccionado':''" style="text-align:left" @click="itemClick(slotProps)">
                    <div class="p-col-10" style="font-size:22;font-weight:900">{{slotProps.data.expediente._id}} {{slotProps.data.expediente.nombre}}</div>
                    <div class="p-col-2">
                        <i class="pi pi-heart" v-if="(slotProps.data) && ('preclinica' in slotProps.data)"/>
                        <i class="pi pi-inbox p-ml-2" />
                    </div>
                    <div class="p-col-12">{{slotProps.data.razon}}</div>
                </div>
            </template>
        </DataView>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
            fecha       : null 
        }
    },
    // props : {
    //     consultas : {
    //         type        : Array,
    //         required    : true,
    //         default     : []
    //     },
    // },
    mounted() {
        this.fecha = new Date() ;
    },
    watch: {
        fecha(nue){
            this.$store.dispatch('loadConsultas',new Date(nue.getTime() - (nue.getTimezoneOffset()*60000)).toISOString().slice(0, 10)).then(
                () => console.log('datos cargados')
            )
        }
    },
    computed: {
        ...mapGetters({
            consultas : 'getConsultas'
        }),
        items(){
            // var fecha_ = this.fecha 
            // fecha_.setHours(0,0,0,0)
            // fecha_ = fecha_.toISOString().split('T')[0]
            // return this.consultas.filter(f => {
            //     // const r = ((f.cita_) && (f.cita_.consulta_) && ('fecha' in f.cita_.consulta_))?(new Date(f.cita_.consulta_.fecha.seconds*1000)).toISOString().split('T')[0]:'N/D'
            //     var r = ((f.consulta) && (f.consulta.consulta) && ('fecha' in f.consulta.consulta))?(f.consulta.consulta.fecha):null
            //     if (!r) return false
            //     r.setHours(0,0,0,0)
            //     r = r.toISOString().split('T')[0]
            //     return ( (f.consulta) && (f.consulta.consulta) && ('fecha' in f.consulta.consulta) && (r === fecha_ ))
            // })
            return this.consultas ;
        }
    },
    methods:{
        itemClick(item){
            this.consultas.forEach((element,index) => {
                if ((index !== item.index) && ('seleccionado' in element)){
                    delete element.seleccionado
                }
            })
            item.data.seleccionado = item.data.seleccionado?false:true
            this.$emit('itemClick',item.data)
        }
    }
}
</script>

<style scoped>
    .seleccionado {
        background-color: lightblue;
    }
</style>