<template>
    <div :class="'p-field p-mt-2 p-col-'.concat(width)">
        <span :class="'p-float-label '+(icon?'p-input-icon-left':'')">
            <i v-if="icon" :class="'pi '+(icon?'pi-search':'')" />
            <InputNumber 
                :showButtons="verButtons"
                :buttonLayout="ButtonsLayout"
                v-model="data" 
                :mask="mask"
                :id="field" 
                :type="type" 
                @input="modified" 
                @keyup="key" x-webkit-speech/>
            <label :for="field">{{title}}</label>
        </span>
    </div>
</template>


<script>
export default {
    props : {
        placeholder : String,
        sufijo      : String,
        prefijo     : String,
        mask        : {
            type    : String,
            default : () => '999'
        },
        field       : {
            type        : String,
            required    : true
        },
        title       : {
            type        : String,
            required    : true
        },
        type        : String,
        icon        : Boolean,
        value       : {
            type    : Number,
            default : () => 0
        },
        verButtons  : {
            type    : Boolean,
            default : () => false
        },
        ButtonsLayout   : {
            type        : String,
            default     : () => 'horizontal'
        },
        width           : {
            type        : Number,
            default     : () => 12
        }
    },
    data(){
        return {
            data : this.value,
            key_ : null
        }
    },
    watch: {
        value(niu){
            this.data = niu
        }
    },
    mounted() {
        this.$emit("modified",Number(0)) ;
    },
    methods: {
        modified(evt){
            this.data = evt.value
            console.log('this.data===',evt)
            console.log('this.data',this.data)
            this.$emit('modified',this.data)            
        },
        key(evt){
            if (evt.key==='Enter') {
                this.$emit('keyenter',this.data)
            }                
        }
    },
}
</script>