<template>
    <div class="p-col p-grid p-fluid" visibility="'collapsed'">
        
        <i class="pi pi-map-marker icono" style="padding:5px; font-size: 2rem" @click="showMapa"/> 
        Latitud {{gps.latitude}} Longitud {{gps.longitude}}
        <i class="pi pi-lock" style="padding:5px; font-size: 2rem" @click="locked=false" v-if="locked"/>
        <i class="pi pi-unlock" style="padding:5px; font-size: 2rem" @click="locked=true" v-if="!locked"/>
        <div class="p-col-2 p-field">
            <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                    <i class="pi pi-search" />
                </span>
                <span class="p-float-label">
                    <InputNumber v-model="zoom" id="field"/>
                    <label for="field">{{title}}</label>
                </span>
            </div>
            
        </div>
        <Dialog v-model:visible="verMapa" modal style="width:80vw; height:80vh" @show="desplegado">
            <div id="mapa" name="mapa" ref="mapa" style="width:75vw; height:75vh" />
        </Dialog>
    </div>
</template>

<script>
import mapboxGl from 'mapbox-gl';

export default {
    props:{
        tipo : {
            type    : Number,
            default : 1
        },
        title : {
            type    : String,
            default : () => 'zoom'
        }
    },
    data() {
        return {
            locked      : false,
            gps         : { latitude : 0, longitude : 0},
            id          : null,
            verMapa     : false,

            coordinates : [],
            accessToken : 'pk.eyJ1IjoiamVzc2FteWZ1ZW50ZXMiLCJhIjoiY2tnZTNpMG41MDdycDJycnI4enlndzRiOCJ9.xJXKtbHhPt3G78fyftQJDA', // your access token. Needed if you using Mapbox maps
            mapStyle    : 'mapbox://styles/mapbox/streets-v12', //'mapbox://styles/mapbox/streets-v12', // your map stylemapbox.satellite
            zoom        : 15.5,
            mapa        : null,
            geojson     : null,
        }
    },
    unmounted() {
        if (this.id)
            this.$clearLocationWatch(this.id)
    },
    mounted() {
        if (this.tipo===1){
            this.id = this.$watchLocation().then(
            (pos) => {
                this.gps.latitude = pos.lat
                this.gps.longitude= pos.lng
                this.coordinates = [pos.lng, pos.lat]
                // this.$emit('modified',this.gps) 
                this.$emit('modified',{ type : "Point", "coordinates" : [this.gps.longitude, this.gps.latitude ] }) 
            }, 
            (error) => {
                console.log('error',error) ;
            }) 
            console.log('pos ====> ',this.id)
        }else{
            this.$getLocation().then((coordinates) => {
                this.gps.latitude = coordinates.lat
                this.gps.longitude= coordinates.lng
                this.coordinates = [coordinates.lng, coordinates.lat]
                this.$emit('modified',{ type : "Point", "coordinates" : [this.gps.longitude, this.gps.latitude ] } ) 
                // this.$emit('modified',this.gps) 
                // console.log('coordinates get ====>',coordinates)
            })
        }
    },
    methods: {
        close(){
            if (this.id)
                navigator.geolocation.clearWatch(this.id)
        },
        showMapa(){
            this.verMapa = !this.verMapa ;
        },  
        desplegado(){
            if (!this.locked){
                mapboxGl.accessToken = this.accessToken;
                const map = new mapboxGl.Map({
                    container   : "mapa",
                    style       : this.mapStyle,
                    center      : this.coordinates,
                    zoom        : this.zoom
                });
                map.addControl(new mapboxGl.NavigationControl());
                
                this.here = new mapboxGl.Marker()
                .setLngLat(this.coordinates)
                .addTo(map);
                map.on('click', event => {
                    console.log('evet posicion',event)
                    if (!this.locked){
                        this.coordinates    = event.lngLat ;
                        this.gps.longitude  = this.coordinates.lng
                        this.gps.latitude   = this.coordinates.lat
                        this.here.remove() ;
                        this.here = new mapboxGl.Marker()
                            .setLngLat(this.coordinates)
                            .addTo(map);

                        this.$emit('modified',{ type : "Point", "coordinates" : [this.gps.longitude, this.gps.latitude ] }) 
                    // }else{

                    }
                })
                this.mapa = map ;
            }else{
                this.verMapa = false ;
                alert('La edicion de su georeferencia esta bloqueada, desbloqueela para poder modificar la misma de manera manual.')
            }
        }
    },
    
}
</script>

<style>
    .icono:hover {
        background-color : blue ;
    }
    @import "https://api.tiles.mapbox.com/mapbox-gl-js/v0.53.0/mapbox-gl.css" ;
    .mapa {
        height: 50vh;
        width: 50vw;
        padding-left: 5rem;
    }

    .mapboxgl-popup{
        transform:none !important;
        top: 10px;
        left: 10px;
    }
    .mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
    .mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
    .mapboxgl-popup-anchor-center .mapboxgl-popup-tip,
    .mapboxgl-popup-anchor-left .mapboxgl-popup-tip,
    .mapboxgl-popup-anchor-right .mapboxgl-popup-tip,
    .mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip,
    .mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
    .mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip, 
    .mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip{
        display:none !important;
    }

</style>