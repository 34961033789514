import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
// import 'firebase/compat/messaging'
import 'firebase/compat/storage'

const firebaseConfig = {
    apiKey: "AIzaSyAcfJs1DEE2KZj6MdXteTnSbisM1CFSDKs",
    authDomain: "epi-sinager-aa71b.firebaseapp.com",
    projectId: "epi-sinager",
    storageBucket: "epi-sinager.appspot.com",
    messagingSenderId: "252780201921",
    appId: "1:252780201921:web:13a0513a8055fab7685140"
  };
  

  firebase.initializeApp(firebaseConfig)

  // utils
  const db          = firebase.firestore()
  const auth        = firebase.auth()
  // const messaging   = firebase.messaging() 
  const storage     = firebase.storage()
  // export utils/refs


export {
    db, 
    auth,
    storage,
    // messaging,
    firebase
}