<template>
    <div class="p-col-12 p-grid p-fluid" >
        <div class="p-col-12">
            <!-- {{ centro }} -->
        </div>
        <Card class="p-col-12 p-xl-8 p-md-12 p-lg-8">
            <template #content>
                <div id="mapa" name="mapa" ref="mapa" class="mapa">
                    <nav id="capas">
                        <a href="" @click.prevent="procesa('divisionPol',['division-politica-fills'])" id="divisionPol"  class="active">Division Politica</a> 
                        <a v-if="georef.length > 0" href="" @click.prevent="procesa(`${georef[0].name}`,
                                                [`${georef[0].name}-layer`,`${georef[0].name}-cluster-count`,`${georef[0].name}-unclustered-point`,])" :id="georef[0].name">{{georef[0].name}}</a> 
                        <a href="" @click.prevent="procesa('semaforoOpt',['semaforo-fills'])" id="semaforoOpt">Semaforo</a> 
                        <a href="" @click.prevent="alert('Opcion pendiente')">Focalizacion</a> 
                        <!-- <a href="" @click.prevent="alert('Opcion pendiente')">Todos</a> -->
                    </nav>
                </div>
            </template>
        </Card>
        <Card class="p-col-12 p-xl-4 p-md-12 p-lg-4">
            <template #header>Informacion del area indicada</template>
            <template #content v-if="informacion" >
                <div class="p-col-12 p-grid p-fluid" style="text-align:left">                    
                    <template v-for="titulo in Object.keys(informacion)" :key="titulo">
                        <div class="p-col-6">{{ titulo }}</div>
                        <div class="p-col-6">{{ informacion[titulo] }}</div>
                    </template>
                </div>
            </template>
        </Card>
    </div>

</template>
<script>
import mapboxgl         from 'mapbox-gl';
import dep              from '../../zonas/deptos.json' ;
// import caserio          from '../../zonas/caserios.json' ;
import { mapGetters }   from 'vuex';
export default {
    props : {
        
    },
    data(){
        return {
            mapInfo : {},
            coordinates : [-85.81111535997086,  14.558964586904096],
            accessToken : 'pk.eyJ1IjoiamVzc2FteWZ1ZW50ZXMiLCJhIjoiY2tnZTNpMG41MDdycDJycnI4enlndzRiOCJ9.xJXKtbHhPt3G78fyftQJDA', // your access token. Needed if you using Mapbox maps
            mapStyle    : 'mapbox://styles/mapbox/streets-v12', //'mapbox://styles/mapbox/streets-v12', // your map stylemapbox.satellite
            zoom        : 6.5,
            mapa        : null,
            geojson     : null,
            centro      : null,
            informacion : null,

            semaforoColor: ['#00ff00','#ffff00','#ff0000'],
        }
    },
    computed: {
        ...mapGetters({
            georef  : 'getGeoreferencias',
            semaforo: 'getSemaforo'
        }),
        
        referencias(){
            return {
                type    : 'FeatureCollection',
                features: this.georef.map( m => Object.assign({ type : 'Feature'}, { geometry : m.ubicacion }))
            } ;
        },
        deptos(){
            const deptos_ = {...dep}
            deptos_.features.forEach((e,i) => {
                const c = Object.assign(e,{id : Number(e.properties.COD_MUNIC)})
                deptos_.features[i] = c
            })
            return deptos_
        }

    },
    watch: {
        semaforo : {
            deep : true,
            handler : function(nue){
                // console.log('semaforo pre',this.georef[0].features)
                if ((nue) && (this.georef[0].features) ){
                    const origen = this.georef[0].features.slice(0,this.georef[0].features.length -1)
                    // console.log('NUE.AGRUPADO',nue)
                    const res = origen.reduce((all, item) => {
                        if (!(item.properties[nue.agrupado.field] in all))
                            all[item.properties[nue.agrupado.field]] = {
                                valor       : 0,
                                min         : 0,
                                max         : 0,
                                items       : []
                            }
                        all[item.properties[nue.agrupado.field]].items.push(item) 
                        all[item.properties[nue.agrupado.field]].valor = Math.round( all[item.properties[nue.agrupado.field]].items.reduce((a,i)=>{
                            a +=  Number(i.properties[nue.indicador.field])
                            return a
                        },0) / all[item.properties[nue.agrupado.field]].items.length )
                        return all
                    },{})

                    const feat = []
                    Object.keys(res).forEach(element => {
                        const r = this.deptos.features.find( f => f.properties.COD_MUNIC === element )
                        if (r){
                            console.log('r >>>>>>>>>',res[r])
                            const t = Object.assign({},{
                                id          : element,
                                geometry    : r.geometry, 
                                properties  : Object.assign({},res[element],{ COLOR : this.semaforoColor[res[element].valor]}),
                                type        : "Feature"
                            })
                            res[element].geometry = r.geometry
                            feat.push( t )
                        }
                    })

                    const col = {
                        type        : "FeatureCollection",
                        features    : feat
                    }

                    console.log("GeoJSON >>>>>>>",col)

                    if (this.map.getLayer('semaforo-fills')) 
                        this.map.removeLayer('semaforo-fills')
                    if (this.map.getSource('semaforo-source')) 
                        this.map.removeSource('semaforo-source')

                    this.map.addSource("semaforo-source",{
                        type : 'geojson',
                        data : col
                    }) ;

                    this.map.addLayer({
                        'id': 'semaforo-fills',
                        'type': 'fill',
                        'source': 'semaforo-source', // reference the data source
                        'layout': {
                            visibility : 'visible',
                        },
                        'paint': {
                            'fill-color': ['get', 'COLOR' ], // blue color fill
                            'fill-opacity': [
                                'case',
                                ['boolean', ['feature-state', 'hover'], false],
                                1,
                                0.7
                            ]
                            }
                    });

                }
            }
        },
        georef : {
            deep : true,
            handler : function(nue){
                console.log('nue',nue)
                if ((nue.length > 0) && ('name' in nue[0])){

                    if (this.map.getLayer(nue[0].name.concat('-layer'))) 
                        this.map.removeLayer(nue[0].name.concat('-layer'))
                    if (this.map.getLayer(nue[0].name.concat('-cluster-count'))) 
                        this.map.removeLayer(nue[0].name.concat('-cluster-count'))
                    if (this.map.getLayer(nue[0].name.concat('-unclustered-point'))) 
                        this.map.removeLayer(nue[0].name.concat('-unclustered-point'))
                    if (this.map.getSource(nue[0].name)) 
                        this.map.removeSource(nue[0].name)

                    this.map.addSource(nue[0].name, {
                        type                : 'geojson',
                        data                : nue[0],
                        cluster             : true,
                        clusterMaxZoom      : 14, // Max zoom to cluster points on
                        clusterRadius       : 50, // Radius of ea                    
                    })
    
                    this.map.addLayer({
                        id      : nue[0].name.concat('-layer'),
                        type    : 'circle',
                        source  : nue[0].name,
                        layout : {
                            visibility : 'visible'
                        },
                        paint: {
                            'circle-color': [
                                'step',
                                ['get', 'point_count'],
                                '#51bbd6',
                                100,
                                '#f1f075',
                                750,
                                '#f28cb1'
                            ],
                            'circle-radius': [
                                'step',
                                ['get', 'point_count'],
                                20,
                                100,
                                30,
                                750,
                                40
                            ]
                        }
                    });
    
                    this.map.addLayer({
                        id: nue[0].name.concat('-cluster-count'),
                        type: 'symbol',
                        source: nue[0].name,
                        filter: ['has', 'point_count'],
                        layout: {
                            visibility      : 'visible',
                            'text-field'    : '{point_count_abbreviated}',
                            'text-font'     : ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                            'text-size'     : 12
                        }
                    });
                
                    this.map.addLayer({
                        id      : nue[0].name.concat('-unclustered-point'),
                        type    : 'symbol',
                        source  : nue[0].name,
                        filter  : ['!', ['has', 'point_count']],
                        layout: {
                            'icon-image': 'custom-marker',
                            visibility : 'visible'
                        }
                    })
    
                    this.map.on('mouseenter',nue[0].name.concat('-unclustered-point'), (e) => {
                        // const coordinates = e.features[0].geometry.coordinates.slice();
                        // const description = JSON.stringify(e.features[0].properties);
                        
                        // // Ensure that if the map is zoomed out such that multiple
                        // // copies of the feature are visible, the popup appears
                        // // over the copy being pointed to.
                        // while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                        //     coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                        // }
                        
                        // new mapboxgl.Popup()
                        // .setLngLat(coordinates)
                        // .setHTML(description)
                        // .addTo(this.map);
                        this.informacion = e.features[0].properties
                    })
    
                    this.map.on('mouseenter',nue[0].name.concat('-clustered-point'), (e) => {
                        const coordinates = e.features[0].geometry.coordinates.slice();
                        const description = e.features[0].properties._index;
                        
                        // Ensure that if the map is zoomed out such that multiple
                        // copies of the feature are visible, the popup appears
                        // over the copy being pointed to.
                        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                        }
                        
                        new mapboxgl.Popup()
                        .setLngLat(coordinates)
                        .setHTML(description)
                        .addTo(this.map);
    
                    })
    
                    this.map.on('mouseenter', nue[0].name.concat('-clustered-point'), () => {
                        this.map.getCanvas().style.cursor = 'pointer';
                    });
                    
                    // Change it back to a pointer when it leaves.
                    this.map.on('mouseleave', nue[0].name.concat('-clustered-point'), () => {
                        this.map.getCanvas().style.cursor = '';
                    });
                }
            }
        }
    },
    mounted() {
        // console.log('localstorage',localstorage);
        mapboxgl.accessToken = this.accessToken;
        const map = new mapboxgl.Map({
            container   : "mapa",
            style       : this.mapStyle,
            center      : this.coordinates,
            zoom        : this.zoom
        });
        map.addControl(new mapboxgl.NavigationControl());
        
        map.on("load", () => {
            map.loadImage(
                'https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png',
                
                function (error, image) {
                    if (error) throw error;
                    map.addImage('custom-marker', image);
            });

            map.addSource('division-politica', {
                type: 'geojson',
                data: this.deptos
            })

            map.addLayer({
            id          : 'division-politica-fills',
            type        : 'fill',
            source      : 'division-politica', // reference the data source
            layout      : {
                visibility : 'visible',
            },
            paint       : {
                // 'fill-color': ['get', 'MUNIC_COL' ], // blue color fill
                'fill-color': ['get', 'COLOR' ], // blue color fill
                'fill-opacity': [
                    'case',
                    ['boolean', ['feature-state', 'hover'], false],
                    1,
                    0.4
                ]}
            });

            map.on('mousemove', 'division-politica-fills', (e) => {
                if (e.features.length > 0) {
                    if (hoveredPolygonId !== null) {
                        map.setFeatureState(
                            { source: 'division-politica', id: hoveredPolygonId },
                            { hover: false }
                        );
                    }
                    console.log('e.features[0].ID_0',e.features[0].properties)
                    this.informacion = e.features[0].properties
                    hoveredPolygonId = e.features[0].id;
                    map.setFeatureState(
                            { source: 'division-politica', id: hoveredPolygonId },
                            { hover: true }
                        );
                }
            });
 
            // // When the mouse leaves the state-fill layer, update the feature state of the
            // // previously hovered feature.
            var hoveredPolygonId = null
            map.on('mouseleave', 'division-politica-fills', () => {
                if (hoveredPolygonId !== null) {
                    map.setFeatureState(
                        { source: 'division-politica', id: hoveredPolygonId },
                        { hover: false }
                    );
                }
                hoveredPolygonId = null;
            });

            /**
             * esta seccion es equivalente a la capa de datos que se quiere que se refleje en el mapa,
             * lo ideal es que se pueda determinar cual de todas las opciones estara visble
             */

            // map.addSource("visitas", {
            //     type                : 'geojson',
            //     data                : this.referencias,
            //     cluster             : true,
            //     clusterMaxZoom      : 14, // Max zoom to cluster points on
            //     clusterRadius       : 50, // Radius of ea                    
            // });
            // map.addLayer({
            //     id      : 'datos',
            //     type    : 'circle',
            //     source  : 'visitas',
            //     paint: {
            //         'circle-color': [
            //             'step',
            //             ['get', 'point_count'],
            //             '#51bbd6',
            //             100,
            //             '#f1f075',
            //             750,
            //             '#f28cb1'
            //         ],
            //         'circle-radius': [
            //             'step',
            //             ['get', 'point_count'],
            //             20,
            //             100,
            //             30,
            //             750,
            //             40
            //         ]
            //         }
            // });
            // map.addLayer({
            //     id: 'cluster-count',
            //     type: 'symbol',
            //     source: 'visitas',
            //     filter: ['has', 'point_count'],
            //     layout: {
            //         'text-field': '{point_count_abbreviated}',
            //         'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            //         'text-size': 12
            //     }
            // });
            // map.addLayer({
            //     id: 'unclustered-point',
            //     type: 'symbol',
            //     source: 'visitas',
            //     filter: ['!', ['has', 'point_count']],
            //     'layout': {
            //         'icon-image': 'custom-marker',
            //     }
            // });
        })

        map.on('dragend', () => {
            this.centro = map.getCenter()
            
        });
        
        this.map = map ;

    },
    methods: {
        procesa(btn, layers){
            layers.forEach( layer => {
                const visibility = this.map.getLayoutProperty(
                    layer, //'division-politica-fills',
                    'visibility'
                );
                
                console.log(layer, visibility)
                if (visibility === 'visible') {
                    this.map.setLayoutProperty(layer,  'visibility', 'none');
                    document.getElementById(btn).className = '';
                } else {
                    this.map.setLayoutProperty(layer, 'visibility','visible');
                    document.getElementById(btn).className = 'active';
                }
            })

            return false ;
        }
    },
}
</script>
<style scoped>
    /* @import "https://api.tiles.mapbox.com/mapbox-gl-js/v2.14.1/mapbox-gl.css" ; */
    /* @import "mapbox-gl/dist/mapbox-gl.css" ; */
    
    .mapa {
        top     : 0;
        bottom  : 0;
        width   : 100% ;
        height  : 35rem ;
    }

    .mapboxgl-popup{
        transform:none !important;
        top: 10px;
        left: 10px;
    }
    .mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
    .mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
    .mapboxgl-popup-anchor-center .mapboxgl-popup-tip,
    .mapboxgl-popup-anchor-left .mapboxgl-popup-tip,
    .mapboxgl-popup-anchor-right .mapboxgl-popup-tip,
    .mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip,
    .mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
    .mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip, 
    .mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip{
        display:none !important;
    }

    #capas {
        background: #fff;
        position: absolute;
        z-index: 1;
        top: 10px;
        left: 10px;
        border-radius: 3px;
        width: 120px;
        border: 1px solid rgba(0, 0, 0, 0.4);
        font-family: 'Open Sans', sans-serif;
    }
        
    #capas a {
        font-size: 13px;
        color: #404040;
        display: block;
        margin: 0;
        padding: 0;
        padding: 10px;
        text-decoration: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.25);
        text-align: center;
    }
        
    #capas a:last-child {
        border: none;
    }
        
    #capas a:hover {
        background-color: #f8f8f8;
        color: #404040;
    }
    
    #capas a.active {
        background-color: #3887be;
        color: #ffffff;
    }
        
    #capas a.active:hover {
        background: #3074a4;
    }
</style>