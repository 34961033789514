<template>
    <div class="p-col-12 p-grid p-fluid p-mt-4">
        <!-- <div class="p-col-12" v-for="pregunta in doc.preguntas" :key="pregunta">             -->
            <template v-for="pregunta in doc.contenido" :key="pregunta">            
                <!-- {{ pregunta.title }} -->
            <!-- <div style="text-align:left"> -->
                <component 
                    :disabled="true"
                    :width="pregunta.width"
                    :is="'custom-'+pregunta['field-type'].value" 
                    :field="pregunta['field-name']"
                    :ffield="pregunta.contenido.map(m=>m.field)"
                    :title="pregunta.pregunta"
                    :ftitle="pregunta.contenido.map(m=>m.title)"
                    :opciones="pregunta.contenido?pregunta.contenido:[]"
                    optionLabel="etiqueta"
                    :multi="pregunta['field-type'].multi" 
                    v-bind:value="data[pregunta['field-name']]"
                    :isSearch="pregunta.searchField"
                    :icon="pregunta.searchField"
                    @modified="procesaCampo($event,pregunta['field-name'])"                    
                />
                <!-- @keyenter="buscar($event,pregunta)"                                        -->
            <!-- </div> -->
        </template>
    </div>
</template>

<script>
// import moment from 'moment'


import { mapGetters } from 'vuex'
export default {
    props : {
        info : {
            type : Object,
            default : () => Object.create({})
        }
    },
    data(){
        return {
            data : this.info
        }
    },
    computed: {
        ...mapGetters({
            doc         : 'getInstrumento',
        }),
    },
    methods: {
        procesaCampo(evt,campo){
            // console.log(`procesaCampo ${campo}`,evt)
            // this.data[campo] = moment.isDate( evt)?new Date(evt):evt ;
            this.data[campo] = evt ;
        },
        salvar(){
            // inicializar campos cuando se llega aqui por busqueda
            console.log('setCapture',{ capture : this.doc._id, data : this.data } )
            this.$store.commit('setCapture',{ capture : this.doc._id, data : this.data } )
        }
    },
}
</script>

<style>

</style>