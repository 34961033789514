<template>
    <div :class="'p-field p-mt-2 p-col-'.concat(width)">
        <span :class="'p-float-label' + (icon?' p-input-icon-left':'')">
            <i v-if="icon" class="pi pi-calendar" />
            <Calendar  v-model="data" :showTime="includeTime" :showButtonBar="true" @date-select="cambia"/>
            <label>{{title}}</label>
        </span>
    </div>
</template>


<script>
export default {
    data(){
        return {
            // data : (this.value)?new Date(this.value):new Date()
            data : this.value
        }
    },
    watch: {
        value(niu){
            if (!niu){
                // console.log('niu changed',niu)
                this.data = new Date()
                this.$emit('modified',this.data)
            }
        }
    },
    props : {
        width : {
            type    : Number,
            default : () => 12
        },
        includeTime : {
            type : Boolean,
            default : false 
        },
        field : {
            type : String,
            required : true
        },
        title : {
            type : String,
            required : true
        },
        icon : String,
        type : String,
        value: {
            type : Date,
            default : () => { return new Date() } 
        }
    },
    mounted() {
        this.data = this.value
        this.$emit('modified',this.data)
    },
    methods: {
        cambia(){
            this.$emit('modified',this.data)
        }
    },
}
</script>