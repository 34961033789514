<template>
    <div class="p-field p-col-12">
        <span :class="'p-float-label '+(icon?'p-input-icon-left':'')">
            <i v-if="icon" :class="'pi '+(icon?'pi-search':'')" />
            <InputNumber 
                :showButtons="verButtons"
                :buttonLayout="ButtonsLayout"
                v-model="data" 
                :id="field" 
                :type="type" 
                :minFractionDigits="decimales" :maxFractionDigits="decimales"
                @input="modified" 
                @keyup="key" x-webkit-speech/>
            <label :for="field">{{title}}</label>
        </span>
    </div>
</template>


<script>
export default {
    props : {
        placeholder : String,
        sufijo      : String,
        prefijo     : String,

        field       : {
            type        : String,
            required    : true
        },
        title       : {
            type        : String,
            required    : true
        },
        type        : String,
        icon        : Boolean,
        value       : {
            type    : Number,
            default : () => 0
        },
        verButtons  : {
            type        : Boolean,
            default     : () => false
        },
        ButtonsLayout : {
            type        : String,
            default     : () => 'horizontal'
        },
        decimales : {
            type        : Number,
            default     : () => 2
        }
    },
    data(){
        return {
            data : this.value,
            key_ : null
        }
    },
    watch: {
        value(niu){
            this.data = niu
        }
    },
    methods: {
        // modified(){
        modified(evt){
            this.data = evt.value
            this.$emit('modified',this.data)            
        },
        key(evt){
            if (evt.key==='Enter') {
                this.$emit('keyenter',this.data)
            }                
        }
    },
}
</script>