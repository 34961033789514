import { createRouter, createWebHashHistory } from "vue-router"
import { auth } from "./firebaseConfig"

const routes = [
    {
        path : '/login',
        name : 'login',
        component : () => import('./paginas/seguridad/login.vue'),        
    },
    {
        path : '/logout',
        name : 'logout',
        component : () => import('./paginas/seguridad/logout.vue'),        
    },
    {
        path : '/registro',
        name : 'registro',
        component : () => import('./paginas/seguridad/registro.vue'),        
    },
    {
        path        : "/mapa",
        name        : "mapa",
        component   : () => import("./components/componentes/Directorio"),
    },
    {
        path : '/instrumento/:nombre',
        component: () => import('./paginas/instrumento.vue')
    },
    {

        beforeEnter: (to, from, next) => {
            auth.onAuthStateChanged(user => {
                if (!user && (to.path !== '/login')){
                    next('login')
                }else{
                    next()
                }    
            })
        },
        path        : "/clinico",
        // name        : "expediente"
        component   : () => import("./paginas/microapps/main.vue"),
        children    : [
            {
                path        : "",
                name        : "dashboard",
                component   : () => import('./paginas/microapps/ficha.vue')
            },
            {
                path        : "/counter",
                name        : "counter",
                component   : () => import('./paginas/microapps/counter.vue')
            },
            {
                path        : "/preclinica",
                name        : 'preclinica',
                component   : () => import('./paginas/microapps/preclinica.vue')
            },
            {
                path        : "/clinica",
                name        : 'clinica',
                component   : () => import('./paginas/microapps/clinica.vue')
            },
            {
                path        : "/paciente",
                name        : 'paciente',
                component   : () => import('./paginas/microapps/paciente.vue')
            },
            {
                path        : "/expediente",
                name        : 'expediente',
                component   : () => import('./paginas/microapps/fullExpediente.vue')
            },
            {
                path        : "/laboratorio",
                name        : 'ordenesLab',
                component   : () => import('./paginas/microapps/laboratorio.vue')
            },
            {
                path        : "/resultados",
                name        : 'resultados',
                component   : () => import('./paginas/microapps/resultados-lab.vue')
            },
        ]
    },
    {
        beforeEnter: (to, from, next) => {
            auth.onAuthStateChanged(user => {
                if (!user && (to.path !== '/login')){
                    next('login')
                }else{
                    next()
                }    
            })
        },
        path        : "/",
        component   : () => import("./paginas/main.vue"),
        children    : [
            {
                path        : '',
                component   : () => import("./paginas/menu.vue")
            },
        ]
    },
    {
        beforeEnter: (to, from, next) => {
            auth.onAuthStateChanged(user => {
                if (!user && (to.path !== '/login')){
                    next('login')
                }else{
                    next()
                }    
            })
        },
        path        : "/proyectos",
        name        : "proyectos",
        component   : () => import("./paginas/main.vue"),
        children    : [
            {
                path        : '',
                component   : () => import("./paginas/proyectos/Proyectos.vue")
            },
            {
                path        : 'proyecto',
                name        : 'proyecto',
                component   : () => import("./paginas/Proyecto.vue")
            },
            {
                path        : 'dinstrumentos',
                name        : 'def-instrumento',
                component   : () => import("./paginas/proyectos/Instrumentos.vue")
            },
            {
                path        : 'dinstrumento',
                name        : 'data-instrumento',
                component   : () => import("./paginas/proyectos/Instrumento.vue")
            },
            {
                path        : 'instrumentos',
                name        : 'instrumentos',
                component   : () => import("./paginas/Instrumentos.vue")
            },
            
        ]
    },
    {
        path        : "/mapas",
        name        : "maps",
        component   : () => import("./paginas/main.vue"),
        children    : [
            {
                path        : '',
                component   : () => import('./paginas/microapps/SAT.vue')
            }
        ]
    },
    {
        path: '/:pathMatch(.*)*',
        redirect : '/'
    }]
    
export default createRouter({
    history : createWebHashHistory(),
    routes
})