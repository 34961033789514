<template>
    <div class="p-field p-col-12">
        <span :class="'p-float-label  '+(icon?'p-input-icon-left':'')">
            <i v-if="icon" :class="'pi '+(icon?'pi-search':'')" />
            <TextArea v-model="data" :id="field" :type="type" @input="modified" @keyup="key" x-webkit-speech rows="8"/>
            <label :for="field">{{title}}</label>
        </span>
    </div>
</template>


<script>
export default {
    
    props : {
        field : {
            type : String,
            required : true
        },
        title : {
            type : String,
            required : true
        },
        type : String,
        icon : String,
        value: [Number, String],
    },
    data(){
        return {
            data : this.value,
            key_ : null
        }
    },
    watch: {
        value(niu){
            this.data = niu
        }
    },
    methods: {
        modified(){
            this.$emit('modified',this.data)            
        },
        key(evt){
            if (evt.key==='Enter') {
                this.$emit('keyenter',this.data)
            }                
        }
    },
}
</script>