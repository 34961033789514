<template>
    <div class="p-grid p-fluid p-col-12">
        <div class="p-col-12">{{title}}</div>
        <div class="p-col-12 p-grid p-fluid p-nogutter">
            <div :class="'p-col-12 p-md-6 p-lg-4 p-xl-'+width" v-for="item in opciones" :key="item.valor">
                <div class="p-field" style="margin:0 10">
                    <label :for="item.valor">{{item.etiqueta}}</label>
                    <InputNumber 
                        :showButtons="verButtons"
                        :buttonLayout="ButtonsLayout"
                        v-model="data[item.valor]" 
                        :id="item.valor"
                    />
                </div>
            </div>            
        </div>
    </div>        

</template>

<script>
export default {
    data(){
        return {
            data : {}
        }
    },
    props : {
        width : Number,
        title : String,
        verButtons : {
            type : Boolean,
            default : true,
        },
        ButtonsLayout : {
            type : String,
            default  : 'horizontal'
        },
        opciones : {
            type : Object,
            default : () => Object.assign({})
        }        
    }
}
</script>

<style>

</style>