import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';

import VueMobileDetection from "vue-mobile-detection";
import { createApp } from 'vue'

import PrimeVue                 from 'primevue/config';
import Card                     from 'primevue/card';
import Button                   from 'primevue/button';
import InputText                from 'primevue/inputtext';
import InputNumber              from 'primevue/inputnumber';
import InputMask                from 'primevue/inputmask';
import Checkbox                 from 'primevue/checkbox';
import MultiSelect              from 'primevue/multiselect';
import Dropdown                 from 'primevue/dropdown';
import Listbox                  from 'primevue/listbox';
import DataTable                from 'primevue/datatable';
import DataView                 from 'primevue/dataview';
import DataViewLayoutOptions    from 'primevue/dataviewlayoutoptions';
import Column                   from 'primevue/column';
import Tooltip                  from 'primevue/tooltip';
import Toolbar                  from 'primevue/toolbar';
import ToggleButton             from 'primevue/togglebutton';
import Sidebar                  from 'primevue/sidebar';
import Panel                    from 'primevue/panel';
import Textarea                 from 'primevue/textarea';
import FileUpload               from 'primevue/fileupload'
import Calendar                 from 'primevue/calendar';
import Carousel                 from 'primevue/carousel';
import Dialog                   from 'primevue/dialog';
import Galleria                 from 'primevue/galleria';
import ScrollPanel              from 'primevue/scrollpanel';
import ConfirmationService      from 'primevue/confirmationservice'
import ConfirmDialog            from 'primevue/confirmdialog'
import TriStateCheckBox         from 'primevue/tristatecheckbox'
import Steps                    from 'primevue/steps' ;
import SelectButton             from 'primevue/selectbutton' ;
import MenuBar                  from 'primevue/menubar' ;
import store                    from './store/store' ;
import axios                    from 'axios' ;
import VueAxios                 from 'vue-axios'
import Toast                    from 'primevue/toast';
import ToastService             from 'primevue/toastservice';
import TreeTable                from 'primevue/treetable';
import Dock                     from 'primevue/dock';
import Picklist                 from 'primevue/picklist';
import Badge                    from 'primevue/badge';
import OverlayPanel             from 'primevue/overlaypanel';

import customTextArea           from './components/componentes/CustomFieldTextArea.vue' ;
import customTextField          from './components/componentes/CustomFieldText.vue' ;
import customNumberField        from './components/componentes/CustomFieldNumber.vue' ;
import customNumberFieldMulti   from './components/componentes/CustomFieldNumberMulti.vue' ;
import customDecimalField       from './components/componentes/CustomFieldDecimal.vue' ;
import customDropdown           from './components/componentes/CustomDropDowm.vue' ;
// import customDropdown           from './components/componentes/CustomPanelSelect.vue' ;
import customMultiSelect        from './components/componentes/CustomMultiSelect.vue' ;
import customDatePicker         from './components/componentes/CustomFieldDate.vue' ;
import customDateTimePicker     from './components/componentes/CustomFieldDateTime.vue' ;
import customMultiDatePicker    from './components/componentes/CustomFieldDateMulti.vue' ;
import customPanelSelect        from './components/componentes/CustomPanelSelect.vue' ;
import customCounter            from './components/componentes/CustomCounter.vue' ;
import Listado                  from './components/componentes/CustomListaConsultas.vue'
import ShowGenerales            from './components/componentes/CustomShowInstrumento.vue'
import DisplayInfo              from './components/componentes/CustomDisplayInfo.vue'
import DisplayInfoSearched      from './components/componentes/CustomDisplayInfoSearched.vue'
import SearchInput              from './components/componentes/CustomSearch.vue'
import InputGenerales           from './components/componentes/CustomInputInstrumento.vue'
import CustomImagen             from './components/componentes/CustomImgGallery.vue'
import CustomGPS                from './components/componentes/CustomGetGeo.vue'
import CustomUser               from './components/componentes/CustomGetUser.vue'
import CustomRange              from './components/componentes/CustomRange.vue'
import BadgeDirective           from 'primevue/badgedirective';
import SplitButton              from 'primevue/splitbutton';

import router                   from './router';
import { auth }                 from './firebaseConfig';

import App                      from './App.vue'
import moment                   from 'moment/moment';

//MAPBOX
import Vue3Geolocation from 'vue3-geolocation';

// imagenes y sonido
import CustomSpeach             from './components/componentes/CustomText2Voice' ;
import CustomScan               from './components/componentes/CustomImage2Text' ;
import CustomMapa               from './components/componentes/CustomMapa' ;

// import VueLocalStorage          from 'vue-localstorage'

auth.onAuthStateChanged(state => {
    console.log("onAuthStateChanged(state)",state)
    // console.log("onAuthStateChanged(state)",state)
    if (state){
        store.commit('setUser',state)
        if (!state.isAnonymous){
            console.log('state change',state)
            // store.dispatch('loadProyectosFB',state.uid)        
            store.dispatch('loadProyectos',state.uid)        
            store.dispatch('loadInit')
        }
    }else{
        router.push('/login')
    }
})

const app = createApp(App);
app.use(moment);
app.use(ToastService);
// app.use(VueLocalStorage) ;
app.directive('tooltip', Tooltip);
app.use(ConfirmationService)
app.use(PrimeVue,{
    locale : {
        choose : 'Archivos de datos'
    }
});
app.use(router);
router.app = app
app.use(VueAxios, axios) ;
app.use(store);
app.use(VueMobileDetection);
app.directive('badge',BadgeDirective);
//app.use(CKEditor);
app.use(Vue3Geolocation);

app.component('Toast',                  Toast);
app.component('MenuBar',                MenuBar);
app.component('Badge',                  Badge);
app.component('Steps',                  Steps);
app.component('Button',                 Button);
app.component('Card',                   Card);
app.component('InputText',              InputText);
app.component('InputNumber',            InputNumber);
app.component('InputMask',              InputMask);
app.component('Checkbox',               Checkbox);
app.component('MultiSelect',            MultiSelect);
app.component('Dropdown',               Dropdown);
app.component('Listbox',                Listbox);
app.component('SelectButton',           SelectButton);
app.component('TriStateCheckBox',       TriStateCheckBox);
app.component('Column',                 Column);
app.component('DataTable',              DataTable);
app.component('DataView',               DataView);
app.component('DataViewLayoutOptions',  DataViewLayoutOptions);
app.component('Toolbar',                Toolbar);
app.component('ToggleButton',           ToggleButton);
app.component('Sidebar',                Sidebar);
app.component('Panel',                  Panel);
app.component('TextArea',               Textarea);
app.component('FileUpload',             FileUpload);
app.component('Calendar',               Calendar);
app.component('Carousel',               Carousel);
app.component('Dialog',                 Dialog);
app.component('Galleria',               Galleria);
app.component('ScrollPanel',            ScrollPanel);
app.component('ConfirmDialog',          ConfirmDialog);
app.component('TreeTable',              TreeTable);
app.component('Dock',                   Dock);
app.component('PickList',               Picklist);
app.component('OverlayPanel',           OverlayPanel);
app.component('SplitButton',            SplitButton);

app.component("custom-textarea",        customTextArea);
app.component("custom-textfield",       customTextField);
app.component("custom-numberfield",     customNumberField);
app.component("custom-numberfieldMulti",customNumberFieldMulti);
app.component("custom-decimalfield",    customDecimalField);
app.component("custom-listview",        customDropdown);
// app.component("custom-listview",        customDropdown);
app.component("custom-datepicker",      customDatePicker);
app.component("custom-datetimepicker",  customDateTimePicker);
app.component("custom-multidatepicker", customMultiDatePicker);
app.component("custom-multiselect",     customMultiSelect);
app.component("custom-panelselect",     customPanelSelect);
app.component("custom-counter",         customCounter);
app.component("custom-imagen",          CustomImagen);
app.component("custom-getgeo",           CustomGPS);
app.component("custom-getuser",         CustomUser);
app.component("listado",                Listado);
app.component("ShowGenerales",          ShowGenerales);
app.component("DisplayInfo",            DisplayInfo);
app.component("DisplayInfoSearched",    DisplayInfoSearched);
app.component("SearchInput",            SearchInput);
app.component("InputGenerales",         InputGenerales);
app.component("CustomOpenRange",        CustomRange);
app.component("CustomRange",            CustomRange);


app.component("CustomSpeach",           CustomSpeach);
app.component("CustomScan",             CustomScan);
app.component("CustomMapa",             CustomMapa);

app.mount('#app')