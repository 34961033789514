<template>
    <div :class="'p-grid p-fluid p-col-'+width">
        <div class="p-col-12">{{title}}</div>
        <div class="p-col-4" v-if="multiselect">
            <Checkbox v-model="todos" value="todos" @change="selector"/>
            <label> Todos</label>
        </div>
        <div class="p-col-12 p-grid">
            <DataView :value="opciones" :layout="layout" >
                <template #grid="slotProps">
                    <!-- <div class="p-col-12 p-md-6 p-lg-4 p-xl-3 p-m-1 flex flex-column"> -->
                    <div class="p-col-12 p-md-6 p-lg-4 p-xl-3 p-m-1">
                        <ToggleButton 
                            class="h-full"
                            v-model="data[slotProps.data.valor]"
                            :offLabel="slotProps.data.etiqueta"
                            :onLabel="slotProps.data.etiqueta"
                            @click="onItemTap(slotProps.data.valor)"
                        />
                    </div>
                </template>
            </DataView>
            <!-- <div :class="'p-col-12 p-md-6 p-lg-4 p-xl-3'" v-for="item in opciones" :key="item.valor">
                <ToggleButton 
                    :disabled="item.disabled"
                    class="h-full"
                    v-model="data[item.valor]"
                    :onLabel="item.etiqueta"
                    :offLabel="item.etiqueta"
                    @click="onItemTap(item.valor)"
                    />
                </div>             -->
                <!-- :disabled="slotProps.data.disabled?slotProps.data.disabled:false" -->        </div>
    </div>        
</template>


<script>
export default {
    props :{
        width : {
            type        : Number,
            required    : true,
            default     : () => 12
        },
        title           : String,
        value           : {
            type : Object,
            default : () => { return {}}
        },
        url : {
            type : String,
            default : () => { return null }
        },
        opciones : {
            type        : Array,
            default : () => { return [] }
        },
        field           : String,
        multiselect   : {
            type        : Boolean,
            default : () => { return true }
        }
    },
    watch: {
        value(niu){
            this.data = niu
        }
    },
    data(){
        return {
            data    : this.value?this.value:{},
            todos   : null,
            layout  : "grid" 
        }
    },
    created() {
        // alert('cargando la data ....')
        this.opciones.forEach(e => {this.data[e.valor] = null})
    },
    methods: {
        onItemTap(campo){
            console.log('campo',campo) 
            if (!this.multiselect){
                Object.keys(this.data).forEach( k=>{
                    if (campo !== k)
                        this.data[k] = false
                })
            }

            const elemento      = this.opciones.filter( f => f.valor === campo )[0]
            const complemento   = this.opciones.filter( f => f.valor !== campo )
            if (('excluyente' in elemento) && elemento.excluyente){
                complemento.forEach( e => {
                    e.disabled = this.data[campo]
                    delete this.data[e.valor]
                })
            }
            this.$emit('modified',this.data)
        },
        selector(){
            console.log('this.opciones',this.opciones)
            this.opciones.forEach(opcion => {
                this.data[opcion.valor] = (Object.keys(this.todos).length > 0)
            })
        },
    },
}
</script>

<style scoped>
</style>