<template>
    <div>
        <div class="p-col" :visibility="visible ? 'visible' : 'collapsed'">
            <i class="pi pi-user icono" style="font-size: 2rem"/> {{ user.email?user.email:'' }}        
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    props :{
        visible : {
            type : Boolean,
            default : true,
        },
        title : {
            type : String,
            default : 'Usuario Actual'
        }
    },
    computed: {
        ...mapGetters({
            user : 'getUser'
        })
    },
    mounted() {
        this.$emit('modified',this.user.uid) 
    },
}
</script>
<style>
    
</style>