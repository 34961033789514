<template>
    <div class="p-grid p-fluid p-col-12">
        <div class="p-col-6">

            <FileUpload name="demo[]" url="./upload.php" @upload="onTemplatedUpload($event)" 
                :multiple="true" accept="image/*" :maxFileSize="1048576" @select="onSelectedFiles">
                <template #header="{ chooseCallback, uploadCallback, clearCallback, files }">
                    <div class="flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
                        <div class="flex gap-2">
                            <Button @click="chooseCallback()" icon="pi pi-images" rounded outlined></Button>
                            <Button @click="uploadEvent(uploadCallback)" icon="pi pi-cloud-upload" rounded outlined severity="success" :disabled="!files || files.length === 0"></Button>
                            <Button @click="clearCallback()" icon="pi pi-times" rounded outlined severity="danger" :disabled="!files || files.length === 0"></Button>
                        </div>
                        <!-- <ProgressBar :value="totalSizePercent" :showValue="false" :class="['md:w-20rem h-1rem w-full md:ml-auto', { 'exceeded-progress-bar': totalSizePercent > 100 }]">
                            <span class="white-space-nowrap">{{ totalSize }}B / 1Mb</span>
                        </ProgressBar> -->
                    </div>
                </template>
                <template #content="{ files, uploadedFiles, removeUploadedFileCallback, removeFileCallback }">
                    <div v-if="files.length > 0">
                        <h5>Pending</h5>
                        <div class="flex flex-wrap p-0 sm:p-5 gap-5">
                            <div v-for="(file, index) of files" :key="file.name + file.type + file.size" class="card m-0 px-6 flex flex-column border-1 surface-border align-items-center gap-3">
                                <div>
                                    <img role="presentation" :alt="file.name" :src="file.objectURL" width="100" height="50" class="shadow-2" />
                                </div>
                                <span class="font-semibold">{{ file.name }}</span>
                                <div>{{ formatSize(file.size) }}</div>
                                <Badge value="Pending" severity="warning" />
                                <Button icon="pi pi-times" @click="onRemoveTemplatingFile(file, removeFileCallback, index)" outlined rounded  severity="danger" />
                            </div>
                        </div>
                    </div>

                    <div v-if="uploadedFiles.length > 0">
                        <h5>Completed</h5>
                        <div class="p-flex p-flex-wrap p-p-0 sm:p-5 p-gap-5">
                            <div v-for="(file, index) of uploadedFiles" :key="file.name + file.type + file.size" class="card m-0 px-6 flex flex-column border-1 surface-border align-items-center gap-3">
                                <div>
                                    <img role="presentation" :alt="file.name" :src="file.objectURL" width="100" height="50" class="shadow-2" />
                                </div>
                                <span class="font-semibold">{{ file.name }}</span>
                                <div>{{ formatSize(file.size) }}</div>
                                <Badge value="Completed" class="mt-3" severity="success" />
                                <Button icon="pi pi-times" @click="removeUploadedFileCallback(index)" outlined rounded  severity="danger" />
                            </div>
                        </div>
                    </div>
                </template>
                <template #empty>
                    <div class="flex align-items-center justify-content-center flex-column">
                        <i class="pi pi-cloud-upload border-2 border-circle p-5 text-8xl text-400 border-400" />
                        <p class="mt-4 mb-0">Arrastre los archivos que desea procesar</p>
                    </div>
                </template>
            </FileUpload>
        </div>


        <div class="p-col-6">
            <img ref="img" :src="src" width="240"  />
        </div>

        <div class="p-col-12">
            <button  class="btn-action" @click="recognize" type="button">Recognize</button>
        </div>
        <br>
        {{ info }}
    </div>
</template>
<script>

import { createWorker } from 'tesseract.js'
export default {
    data(){
        return {
            src     : null ,
            info    : null,
            worker  : null ,
        }
    },
    methods: {
        onSelectedFiles(evt){
            console.log('evt',evt.files) ;

            const reader = new FileReader()
            reader.onload = () => {
                this.src = reader.result  
            }

            if (evt.files.length > 0){
                reader.readAsDataURL(evt.files[0])
            }

            
             
   
        },
        onTemplatedUpload(evt){
            console.log('evt',evt)
        },
        async recognize(){
                this.worker = await createWorker({
                    logger: m => this.info = `${m.status} : ${(m.progress * 100).toFixed(2)}%`
                })
            
                // isLoad.value = true  
                // await this.worker.load()
                await this.worker.loadLanguage('eng')
                await this.worker.initialize('eng')
                
                // await this.worker.setParameters({
                //     tessedit_pageseg_mode: PSM.SINGLE_BLOCK,
                // })
                
                const { data } = await this.worker.recognize(this.$refs.img)
                this.info = data.text ;
                await this.worker.terminate() ;

                this.$store.commit('toSpeech',this.info) ;
            }
    },
}
</script>
<style>
    
</style>