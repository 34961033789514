<template>
    <div :class="'p-field p-mt-2 p-col-'.concat(width)">
        <span :class="'p-float-label '+(icon?'p-input-icon-left':'')">
            <i v-if="icon" :class="'pi '+(icon?'pi-search':'')" />
            <InputText 
                v-model="data" 
                :id="field" 
                :type="type" 
                @input="modified" 
                @keyup="key" x-webkit-speech/>
            <label :for="field">{{title}}</label>
        </span>
    </div>
</template>


<script>
export default {
    props : {
        width : {
            type    : Number,
            default : () => 12
        },
        field : {
            type : String,
            required : true
        },
        title : {
            type : String,
            required : true
        },
        type : String,
        icon : Boolean,
        value: [Number, String],
    },
    data(){
        return {
            data : this.value,
            key_ : null
        }
    },
    watch: {
        value(niu){
            this.data = niu
        }
    },
    methods: {
        modified(){
            this.$emit('modified',this.data)            
        },
        key(evt){
            if (evt.key==='Enter') {
                this.$emit('keyenter',this.data)
            }                
        }
    },
}
</script>