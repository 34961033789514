<template>
    <div>
        <!-- <i class="pi pi-volume-up" @click="hablar"/> -->
        <i class="pi pi-volume-up" @click="hablar" v-if="!isPlaying"/>
        <i class="pi pi-volume-off" @click="pause" v-if="isPlaying"/>
        
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
    export default {
        data(){
            return {
                voices      : null,
                isPlaying   : false,
                meg         : null,
            }
        },
        mounted() {
            this.voices = window.speechSynthesis.getVoices();
            console.log('voices0',this.voices) 
        },
        methods: {
            hablar(){
                this.msg = new SpeechSynthesisUtterance();
                this.msg.text    = this.texto;
                this.msg.volume  = 1.0; // speech volume (default: 1.0)
                this.msg.pitch   = 1.0; // speech pitch (default: 1.0)
                this.msg.rate    = 1.0; // speech rate (default: 1.0)
                this.msg.lang    = 'es-US'; // speech language (default: 'en-US')
                this.msg.voice   = this.voices.find(voice => voice.name === 'Google español de Estados Unidos'); // voice URI (default: platform-dependent)
                // console.log('voices',msg.voice)
                this.msg.onboundary = function (event) {
                    console.log('Speech reached a boundary:', event.name);
                };
                this.msg.onpause = function (event) {
                    console.log('Speech paused:', event.utterance.text.substring(event.charIndex));
                };
                window.speechSynthesis.cancel();
                window.speechSynthesis.speak(this.msg);
                this.isPlaying = true ;

            },
            pause(){
                window.speechSynthesis.pause()
                this.isPlaying = false ;

            }

        },
        computed: {
            ...mapGetters ({
                texto : 'getSpeach',
            })
        },
    }

</script>
<style>
    
</style>