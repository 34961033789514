<template>
    <div class="p-col-12 p-grid">
        <div class="p-col-11">  
            <!-- <div class="p-col-12" v-for="pregunta in componente.preguntas" :key="pregunta">             -->
            <div class="p-col-12" v-for="pregunta in componente.contenido" :key="pregunta">            
                <div class="p-grid p-fluid" style="text-align:left">
                    <div class="p-col-12 p-xl-4 p-lg-6">{{pregunta.pregunta}}</div>
                    <template v-if="Array.isArray(data[pregunta['field-name']])">
                        <div style="font-weight:700" :class="'p-col-12 p-xl-8 p-lg-6'.concat(index===0?'':' p-xl-offset-4 p-lg-offset-6')" v-for="(row,index) in data[pregunta['field-name']]" :key="row">
                            <div>{{informacion(row)}}</div>
                        </div>
                    </template>    
                    <template v-else>
                        <div style="font-weight:700" class="p-col-12 p-xl-8 p-lg-6">{{informacion(data[pregunta['field-name']])}}</div>
                    </template>
                    <!-- <div style="font-weight:700" class="p-col-12 p-xl-8 p-lg-6">{{data[pregunta['field-name']]}}</div> -->                    
                </div>
            </div>

        </div>
        <div class="p-col">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    props: {
        instrumento : {
            type    : String,
            require : true
        },
        info : {
            type    : String,
            require : true 
        },
        path : {
            type    : String,
            require : true,
        }
    },
    methods: {
        informacion(evt){
            var res = evt
            if ((typeof res === 'object' )&&('etiqueta' in res)) res = res.etiqueta
            if (res instanceof Date) res = res.toLocaleDateString('es-ES',{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
            // if (typeof res === 'date' ) res = res.toLocaleDateString('es-ES',{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
            // if ((typeof res === 'object' )&&('seconds' in res)) res = (new Date(res.seconds * 1000)).toLocaleDateString('es-ES',{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
            return res
        }
    },
    computed: {
        ...mapGetters({
            proyectos   : 'getProyectos',
            datos       : `getPaciente`,

            proyecto    : 'getProyecto'
        }),
        data(){
            var res = (this.path.length > 0)?eval(`this.datos${this.path.split('.').map( m => `['${m}']`).join('')}`):this.datos

            return res
        },
        componente(){
            var ret = {}
            const res = this.instrumento.split('.')
            // if (this.proyectos && this.proyectos.length > 0){
            //     if (res.length === 2){
            //         ret = this.proyectos.filter( f => f._id === res[0] )
            //         console.log('this.proyectos',ret)
            //         // ret = ret[0].instrumentos.filter( f => f.id === res[1] )
            //         ret = ret[0].contenido.filter( f => f._id === res[1] )
            //         if (ret.length === 1)
            //         ret = ret[0]
            //     }
            // }
            if (this.proyecto && this.proyecto.contenido)
                if (res.length === 2){
                    ret = this.proyecto.contenido.filter( f => f._id === res[1] )
                    if (ret.length === 1)
                        ret = ret[0]
                }
            console.log('this.instrumento',ret)
            return ret ;
        }
    },
}
</script>

<style>

</style>