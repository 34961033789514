<template>
    <div class="p-col-12" >
        <img ref="preview" style="border-radius:100%;width:20vw;height:20vw;object-fit:cover"/>
    </div>
    <div class="p-col-12">
        <FileUpload 
            :disabled="this.img"
            name="demo[]" 
            mode="basic" 
            :auto="false" 
            :customUpload="true"
            @uploader="myUploader" 
            @select="seleccionaImagen"
            accept="image/*"
            chooseLabel="Seleccionar"
            />        
    </div>
    
</template>

<script>
export default {
    data(){
        return {
            img : null,
            info : null
        }
    },
    methods: {
        myUploader(){
            if (this.img){
                this.$store.dispatch('salvaImagen',{ archivo : this.img, })
            }
        },
        seleccionaImagen(evt){
            this.img = (evt.files.length === 1)?evt.files[0]:null
            const reader = new FileReader() ;
            reader.addEventListener('load', () => {
                this.$refs.preview.src = reader.result
            },false)

            if (this.img)
                reader.readAsDataURL(this.img)

            this.$emit('selectImage',this.img)
        }
    },
}
</script>

<style>

</style>