<template>
    <div :class="'p-field p-grid p-mt-2 p-col-'.concat(width)">
        <div class="p-col-12">
            {{ title }}
        </div>
        <div :class="'p-mt-4 p-col-'.concat((ffield.length>3)?3:12/ffield.length)" v-for="(campo,index) in ffield" :key="campo">
            <span :class="'p-float-label '+(icon?'p-input-icon-left':'')">
                <i v-if="icon" :class="'pi '+(icon?'pi-search':'')" />
                <InputNumber 
                    :showButtons="verButtons"
                    :buttonLayout="ButtonsLayout"
                    v-model="data[campo]" 
                    :mask="mask"
                    :id="campo" 
                    :type="type" 
                    @input="modified($event,campo)" 
                    @keyup="key" x-webkit-speech/>
                <label :for="field">{{ftitle[index]}}</label>
            </span>
        </div>
    </div>
</template>


<script>
export default {
    props : {
        placeholder : String,
        sufijo      : String,
        prefijo     : String,
        mask        : {
            type    : String,
            default : () => '999'
        },
        field       : {
            type        : String,
            required    : true,
        },
        ffield       : {
            type        : Array,
            required    : true,
        },
        title       : {
            type        : String,
            required    : true,
        },
        ftitle      : {
            type        : Array,
            required    : true
        },
        type        : String,
        icon        : Boolean,
        value       : {
            type    : Number,
            default : () => 0
        },
        verButtons  : {
            type    : Boolean,
            default : () => false
        },
        ButtonsLayout   : {
            type        : String,
            default     : () => 'horizontal'
        },
        width           : {
            type        : Number,
            default     : () => 12
        }
    },
    data(){
        return {
            data : {},
            key_ : null
        }
    },
    watch: {
        // value(niu){
        //     this.data = {}
        //     niu.forEach(e => {
        //         this.data[Object.keys(e)[0]] = Object.values(e)[0]
        //     })
        // }
    },
    mounted() {
        // this.$emit("modified",Number(0)) ;
        // this.data[this.field] = {}
    },
    methods: {
        modified(evt,campo){
            this.data[campo] = evt.value
            console.log('modified',this.data )
            this.$emit('modified', this.data )
        },
        key(evt){
            if (evt.key==='Enter') {
                console.log('keyenter',  this.data )
                this.$emit('keyenter', this.data )
            }                
        }
    },
}
</script>